import { Alert, Button, Card, Col, Row } from "react-bootstrap";
import styles from "./effectuer-commande.module.css";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { capturer_transaction, creer_transaction, creer_transaction_avec_uuid_facture } from "../../services/transactionService";
import { obtenirValeur, sauvegarderValeur } from "../../utils/localStorageUtil";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import ReactGA from "react-ga4";

export default function EffectuerCommande() {

    const [state, setState] = useState({
        erreurPaypal: false,
        alertePaypal: {
            type:"",
            texte: ""
        },
        panier: null,
        elementBeginCheckoutAnalytics: false,
        totalCalculated: false,
        totalPanier: 0
    })
    const location = useLocation();
    const navigate = useNavigate();
    const uuid_facture = location.search.split("=")[1];

    useEffect(() => {
        !state.panier && setState({
            ...state,
            panier: obtenirValeur("panier", true)
        })
    }, [state])

    useEffect(() => {
        if(!state.totalCalculated && state.panier && state.panier.length > 0)
        {
            let totalPanierCalculer = 0;
            state.panier.forEach((itemPanier) => {
                totalPanierCalculer += itemPanier.prix_unitaire * itemPanier.quantite * itemPanier.duree
            })
            setState({
                ...state,
                totalCalculated: true,
                totalPanier: totalPanierCalculer
            })
        }
    }, [state])

    useEffect(() => {
        if(state.panier && state.totalCalculated && !state.elementBeginCheckoutAnalytics)
        {
            ReactGA.event("begin_checkout", {
                "currency": "CAD",
                "value": state.totalPanier,
                "items": state.panier.map((item_panier) => {
                    return {
                        "item_id": item_panier.id,
                        "item_name": item_panier.nom,
                        "price": item_panier.prix_unitaire,
                        "quantity": item_panier.quantite * item_panier.duree
                    }
                })
            })
            setState({
                ...state,
                elementBeginCheckoutAnalytics: true
            })
        }
    }, [state])

    return (
        <Row className={styles.conteneur_effectuer_commande}>
            <Col lg="4"></Col>
            <Col lg="4">
                {
                    (!state.panier || state.panier.length === 0) &&
                    <Alert>Veuillez choisir des éléments à mettre dans votre panier.</Alert>
                }
                {
                    state.alertePaypal.type &&
                    <Alert variant={state.alertePaypal.type}>{state.alertePaypal.texte}</Alert>
                }
                {
                    state.panier &&
                    state.panier.length > 0 &&
                    !state.erreurPaypal &&
                    <>
                        {
                            state.panier
                            .map((itemPanier) => {
                                return (
                                    <Card className={styles.element_panier_commande}>
                                        <Card.Body>
                                            <h5>{itemPanier.nom}</h5>
                                            <Card.Text>Prix : {itemPanier.prix_unitaire}$</Card.Text>
                                            <Card.Text>Quantité : {itemPanier.quantite}</Card.Text>
                                            <Card.Text>Durée : {itemPanier.duree}</Card.Text>
                                            <Button 
                                                variant="primary"
                                                onClick={() => {
                                                    if(state.panier.length === 1)
                                                    {
                                                        sauvegarderValeur("panier", [], true)
                                                        setState({
                                                            ...state,
                                                            totalCalculated: false,
                                                            panier: null
                                                        })
                                                    }
                                                    else
                                                    {
                                                        let nombre_item = 0;
                                                        let nouveauPanier = state.panier.filter(() => {
                                                            nombre_item++;
                                                            if(nombre_item === 1) return false;
                                                            return true;
                                                        })
                                                        setState({
                                                            ...state,
                                                            totalCalculated: false,
                                                            panier: nouveauPanier
                                                        })
                                                        sauvegarderValeur("panier", nouveauPanier, true)
                                                    }
                                                    
                                                }}
                                            >Enlever du panier</Button>
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        }
                        <Row className="text-center">
                            <h3 className={styles.total_panier}>Total : {state.totalPanier}$</h3>
                        </Row>
                        <PayPalButtons
                            createOrder={() => {
                                if(uuid_facture)
                                {
                                    return creer_transaction_avec_uuid_facture(uuid_facture, state, setState);
                                }
                                return creer_transaction(state, setState)
                            }}
                            onApprove={(data, actions) => {
                                capturer_transaction(data, state, setState, navigate)
                            }}
                            onCancel={ (data, actions) => {
                                navigate("/tableau-de-bord")
                            }}
                        />
                    </>
                }
            </Col>
            <Col lg="4"></Col>
        </Row>
    );
}