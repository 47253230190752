import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import Navigation from '../composants/commun/navigation';
import TitrePage from '../composants/commun/titre-page';
import { useLocation, useNavigate } from 'react-router';
import { obtenir_page_meta } from '../services/pageService';
import PiedPage from '../composants/commun/pied-page';
import { verifier_token } from '../services/connexionService';
import FormAjoutPage from '../composants/ajouter-page/form-ajouter-page';

export default function PageAjouterPage() {

    const [state, setState] = useState({
        metaPage: null
    });
    let location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        !state.metaPage && obtenir_page_meta(location.pathname, state, setState)
    }, [state, location])

    useEffect(() => {
        verifier_token(navigate, location)
    })

    return (
      <>
        {
          state.metaPage &&
          <Helmet>
            <title>{state.metaPage.titre_onglet}</title>
            <link rel="canonical" href={process.env.REACT_APP_NOM_DOMAINE + state.metaPage.url_canonique} />
            <meta name="description" content={state.metaPage.description} />
            <link rel="icon" href={process.env.REACT_APP_NOM_DOMAINE_API + "/images/icones/icone-gw.png"}/>
          </Helmet>
        }
        <Navigation />
        {
            state.metaPage &&
            <>
                <TitrePage>{state.metaPage.nom_page}</TitrePage>
                <FormAjoutPage />
            </>
        }
        <PiedPage/>
      </>
    );
}