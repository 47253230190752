import { Col, Row } from "react-bootstrap";
import styles from "./titre-page.module.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { obtenir_page_meta } from "../../services/pageService";

export default function TitrePage({ children }) {

    const [state, setState] = useState({
        firstLoad: true,
        metaPage: null
    });
    let location = useLocation();

    useEffect(() => {
        !state.metaPage && !state.firstLoad && obtenir_page_meta(location.pathname, state, setState) &&
        setState({
            firstLoad: false
        })
    }, [state, location])

    return (
        <Row 
            style={{ 
                backgroundImage: "url('" + (state.metaPage && state.metaPage.type_page_id !== 5 && state.metaPage.url_image ? process.env.REACT_APP_NOM_DOMAINE_API + state.metaPage.url_image : "https://api.greenwoodmultimedia.com/images/banniere/foret-de-bouleau.jpg") + "')" 
            }} 
            className={styles.conteneur_titre_page}
        >
            <Col lg="12">
                <Row className="text-center">
                    <Col lg="3"></Col>
                    <Col lg="6">
                        <h1 className={styles.titre_page}>{children}</h1>
                    </Col>
                    <Col lg="3"></Col>
                </Row>
            </Col>
        </Row>
    );
}