import validator from "validator";
import axios from "axios";import { sauvegarderValeur } from "../utils/localStorageUtil";

export function inscription(state, setState)
{
    if(!state.infosInscription.prenom)
    {
       setState({
        ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez fournir votre prénom !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.nom)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez fournir votre nom !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.courriel)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez fournir votre courriel !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(state.infosInscription.courriel && !validator.isEmail(state.infosInscription.courriel))
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez fournir votre courriel du format 'courriel@domaine.com' !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.mot_de_passe)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez entrer votre mot de passe !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.nouveau_mot_de_passe)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez entrer de nouveau votre mot de passe !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(state.infosInscription.mot_de_passe !== state.infosInscription.nouveau_mot_de_passe)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez vous assurez que vous avez bien réecrit votre mot de passe !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.numero_civique)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez entrer votre numéro civique !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.nom_ville)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez entrer le nom de votre ville !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.province_etat_territoire)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez entrer le nom de votre province/état/territoire !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.pays)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez entrer le nom de votre pays !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }
    if(!state.infosInscription.code_postal)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez entrer le nom de votre code postal !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }

    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/utilisateur/inscription",
        {
            "prenom": state.infosInscription.prenom,
            "nom": state.infosInscription.nom,
            "courriel": state.infosInscription.courriel,
            "mot_de_passe": state.infosInscription.mot_de_passe,
            "numero_civique": state.infosInscription.numero_civique,
            "ville": state.infosInscription.nom_ville,
            "province": state.infosInscription.province_etat_territoire,
            "pays": state.infosInscription.pays,
            "code_postal": state.infosInscription.code_postal
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    etapeInscription: 2,
                    analyzedUserRecaptcha: false,
                    alerteInscription: {
                        type: "success",
                        texte: "Votre compte a été créer avec succès ! Un code d'activation vous a été envoyé à votre courriel."
                    }
                })
                document.getElementById('alerte_contact').scrollIntoView({
                    behavior: 'smooth'
                });
                return;
            }
            setState({
                ...state,
                alerteInscription: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue !"
                }
            })
            document.getElementById('alerte_contact').scrollIntoView({
                behavior: 'smooth'
            });
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                alerteInscription: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue !"
                }
            })
            document.getElementById('alerte_contact').scrollIntoView({
                behavior: 'smooth'
            });
        })
}

export function activer_utilisateur_inscription(state, setState, navigation, sourceInscription)
{
    if(!state.infosInscription.code_reinitialisation)
    {
        setState({
            ...state,
            alerteInscription: {
                type: "warning",
                texte: "Veuillez fournir le code d'activation !"
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return; 
    }

    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/utilisateur/activer",
        {
            "recaptcha_token": state.recaptcha_token,
            "courriel": state.infosInscription.courriel,
            "code_reinitialisation": state.infosInscription.code_reinitialisation
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    alerteInscription: {
                        type: "success",
                        texte: "Votre activation a été effectué !"
                    }
                })
                document.getElementById('alerte_contact').scrollIntoView({
                    behavior: 'smooth'
                });
                axios.get(
                    process.env.REACT_APP_NOM_DOMAINE_API + "/utilisateur/profil",
                    {
                        "headers": {
                            "Authorization": "Bearer " + response.data.contenu.token
                        }
                    }
                )
                    .then((responseProfil) => {
                        if(responseProfil.status === 200)
                        {
                            sauvegarderValeur("token", response.data.contenu.token)
                            sauvegarderValeur("profil", responseProfil.data.contenu, true)
                            if(sourceInscription === "commande"){
                                navigation("/effectuer-commande")
                                return;
                            }
                            navigation("/tableau-de-bord")
                            return;
                        }
                        setState({
                            ...state,
                            alerteInscription: {
                                type: "danger",
                                texte: "Une erreur inattendue est survenue !"
                            }
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        setState({
                            ...state,
                            alerteInscription: {
                                type: "danger",
                                texte: "Une erreur inattendue est survenue !"
                            }
                        })
                        document.getElementById('alerte_contact').scrollIntoView({
                            behavior: 'smooth'
                        });
                    })
                return;
            }
            setState({
                ...state,
                alerteInscription: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue !"
                }
            })
            document.getElementById('alerte_contact').scrollIntoView({
                behavior: 'smooth'
            });
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                alerteInscription: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue !"
                }
            })
            document.getElementById('alerte_contact').scrollIntoView({
                behavior: 'smooth'
            });
        })
}