import { Button, Carousel, Col, Row } from "react-bootstrap";
import styles from "./projets-accueil.module.css";
import { useEffect, useState } from "react";
import { obtenir_projets } from "../../services/projetService";
import { creerCleUnique } from "../../utils/utilList";

export default function ProjetsAccueil() {

    const [state, setState] = useState({
        listeProjets: null
    })

    useEffect(() => {
        !state.listeProjets && obtenir_projets(state, setState)
    }, [state])

    return (
        <Row className="text-center">
            <Col lg="2"></Col>
            <Col lg="8">
                <h2 className={styles.titre_projet_accueil}>Nos derniers projets</h2>
                <Carousel touch={false}>
                    {
                        state.listeProjets &&
                        state.listeProjets
                        .map((projet) => {
                            return (
                                <Carousel.Item key={creerCleUnique()}>
                                    <img 
                                        className={"img-fluid " + styles.image_projet} 
                                        src={process.env.REACT_APP_NOM_DOMAINE_API + projet.url_image}
                                        alt={projet.description}
                                    />
                                    <Carousel.Caption
                                        className={styles.caption_projet}
                                    >
                                        <h3>{projet.nom_page}</h3>
                                        <p className={styles.description_projet}>{projet.description}</p>
                                        <Button href={projet.url_canonique}>Voir le projet</Button>
                                    </Carousel.Caption>
                                </Carousel.Item>
                            );
                        })
                    }
                </Carousel>
            </Col>
            <Col lg="2"></Col>
        </Row>
    );
}