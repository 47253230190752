import { useState } from "react";
import { Button, Form, FormControl, InputGroup, Modal, Row } from "react-bootstrap";
import styles from "./recherche.module.css";
import { useNavigate } from "react-router";

export default function Recherche(props) {

    const [state, setState] = useState({
        texteRecherche: ""
    })
    const navigate = useNavigate();

    return (
        <Modal 
            show={props.rechercheAffiche} 
            onHide={props.cacherRecherche}
        >
            <Modal.Body>
                <Row className="text-center">
                    <Form
                        noValidate
                        onSubmit={(event) => {
                            event.preventDefault();
                            navigate("/recherche?s=" + state.texteRecherche)
                            props.cacherRecherche();
                        }}
                    >
                        <InputGroup>
                            <FormControl 
                                type="text"
                                placeholder="Votre recherche..."
                                value={state.texteRecherche}
                                onChange={
                                    (event) => {
                                        setState({
                                            ...state,
                                            texteRecherche: event.target.value
                                        })
                                    }
                                }
                            />
                        </InputGroup>
                        <Button 
                            type="submit"
                            className={styles.bouton_soumettre_recherche}
                            disabled={!state.texteRecherche}
                        >Rechercher</Button>
                    </Form>
                </Row>
            </Modal.Body>
        </Modal>
    );
}