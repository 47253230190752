import { Alert, Card, Col, Row } from "react-bootstrap";
import styles from "./confirmation-commande.module.css";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import { obtenir_facture_par_paypal_uuid } from "../../services/transactionService";
import { creerCleUnique } from "../../utils/utilList";
import ReactGA from "react-ga4";

export default function ConfirmationCommande() {

    const location = useLocation();
    const transaction_id = location.search.split("=")[1];
    const [state, setState] = useState({
        paiementEnregistreAnalytics: false,
        facture: null
    })

    useEffect(() => {
        !state.facture && obtenir_facture_par_paypal_uuid(transaction_id, state, setState)
    }, [state, transaction_id])

    useEffect(() => {
        if(state.facture && !state.paiementEnregistreAnalytics)
        {
            ReactGA.event("purchase", {
                "currency": "CAD",
                "value": state.facture.montant_total_transaction,
                "transaction_id": transaction_id,
                "items": state.facture.produits_transactions.map((item_panier) => {
                    return {
                        "item_id": item_panier.id,
                        "item_name": item_panier.nom,
                        "price": item_panier.prix_unitaire,
                        "quantity": item_panier.quantite * item_panier.duree
                    }
                })
            })
            setState({
                ...state,
                paiementEnregistreAnalytics: true
            })
        }
    }, [state, transaction_id])

    return (
        <Row className={styles.conteneur_confirmation_commande}>
            <Col lg="4"></Col>
            <Col lg="4">
                {
                    transaction_id &&
                    state.facture &&
                    state.facture.statut_transaction_id !== 1 &&
                    <>
                        <Alert>Votre commande a été effectué avec succès ! Vous recevrez votre reçu bientôt via courriel. Vous pouvez vous rendre sur le Tableau de bord afin de suivre l'état de vos services. Vous serez contactez également via courriel afin de discuter plus en détails l'activation de vos services.</Alert>
                        <p>Numéro de commande : {transaction_id}</p>
                        <p>Total de votre commande : {state.facture ? state.facture.montant_total_transaction + " " : 0 + " "} $</p>
                        <h2>Vos produits achetés</h2>
                        {
                            state.facture &&
                            state.facture.produits_transactions
                            .map((item_panier) => {
                                return(
                                    <Card className={styles.item_panier} key={creerCleUnique()}>
                                        <Card.Body>
                                            <Card.Title>{item_panier.nom}</Card.Title>
                                            <Card.Text>Prix: {item_panier.prix_unitaire}$</Card.Text>
                                            {
                                                (item_panier.produit_id === 5 || item_panier.produit_id === 6) &&
                                                <Card.Text>Nombre d'utilisateur: {item_panier.quantite}</Card.Text>
                                            }
                                            <Card.Text>Durée: {item_panier.duree} an(s)</Card.Text>
                                            {
                                                item_panier.nom_domaine &&
                                                <Card.Text>Nom de domaine: {item_panier.nom_domaine}</Card.Text>
                                            }
                                        </Card.Body>
                                    </Card>
                                )
                            })
                        }
                    </>
                }
                {
                    !transaction_id &&
                    <Alert>Aucun numéro de transaction fourni.</Alert>
                }
            </Col>
            <Col lg="4"></Col>
        </Row>
    );
}