import axios from "axios";

export function obtenir_projets(state, setState)
{
    axios(
        process.env.REACT_APP_NOM_DOMAINE_API + "/pages/portfolio",
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    listeProjets: response.data.contenu
                })
                return;
            }
            setState({
                ...state,
                listeProjets: []
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                listeProjets: []
            })
        })
}