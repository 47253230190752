import { Alert, Button, Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import styles from "./form-mot-de-passe-oublier.module.css";
import { useState } from "react";
import { changer_mot_de_passe, envoyer_courriel_reinitialisation, verifier_code_reinitialisation } from "../../services/connexionService";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router";

export default function FormMotDePasseOublier() {

    const [state, setState] = useState({
        analyzedUserRecaptcha: false,
        recaptcha_token: "",
        infosRenitialisation: {
            courriel: "",
            code_reinitialisation: "",
            mot_de_passe: "",
            nouveau_mot_de_passe: ""
        },
        alerteReinitialisation: null,
        etapeReinitialisation: 1
    })
    const navigate = useNavigate();

    return (
        <Row className={styles.conteneur_form_mdp_oublier + " text-center"}>
            <Col lg="4"></Col>
            <Col lg="4">
                <GoogleReCaptcha onVerify={ (recaptcha_token) => {
                    if(!state.analyzedUserRecaptcha)
                    {
                        setState({
                            ...state,
                            analyzedUserRecaptcha: true,
                            recaptcha_token: recaptcha_token
                        })
                    }
                }} />
                {
                    state.alerteReinitialisation &&
                    <Alert variant={state.alerteReinitialisation.type}>{state.alerteReinitialisation.texte}</Alert>
                }
                {
                    state.etapeReinitialisation === 1 &&
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            envoyer_courriel_reinitialisation(state, setState)
                        }}
                    >
                        <FormGroup>
                            <FormLabel>Votre courriel *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosRenitialisation.courriel}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosRenitialisation: {
                                            ...state.infosRenitialisation,
                                            courriel: event.target.value
                                        },
                                        alerteReinitialisation: null
                                    })
                                }}
                                placeholder="johndoe@domaine.com"
                            />
                        </FormGroup>
                        <Button className={styles.bouton_connexion} type="submit">Envoyer courriel de réinitialisation</Button>
                    </Form>
                }
                {
                    state.etapeReinitialisation === 2 &&
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            verifier_code_reinitialisation(state, setState)
                        }}
                    >
                        <FormGroup>
                            <FormLabel>Votre code de réinitialisation *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosRenitialisation.code_reinitialisation}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosRenitialisation: {
                                            ...state.infosRenitialisation,
                                            code_reinitialisation: event.target.value
                                        },
                                        alerteReinitialisation: null
                                    })
                                }}
                                placeholder="123456"
                            />
                        </FormGroup>
                        <Button className={styles.bouton_connexion} type="submit">Vérifier code de réinitialisation</Button>
                    </Form>
                }
                {
                    state.etapeReinitialisation === 3 &&
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            changer_mot_de_passe(state, setState)
                        }}
                    >
                        <FormGroup>
                            <FormLabel>Votre nouveau mot de passe *</FormLabel>
                            <input 
                                className="form-control"
                                type="password"
                                value={state.infosRenitialisation.mot_de_passe}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosRenitialisation: {
                                            ...state.infosRenitialisation,
                                            mot_de_passe: event.target.value
                                        },
                                        alerteReinitialisation: null
                                    })
                                }}
                                placeholder="monMotDePasse"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Votre nouveau mot de passe *</FormLabel>
                            <input 
                                className="form-control"
                                type="password"
                                value={state.infosRenitialisation.nouveau_mot_de_passe}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosRenitialisation: {
                                            ...state.infosRenitialisation,
                                            nouveau_mot_de_passe: event.target.value
                                        },
                                        alerteReinitialisation: null
                                    })
                                }}
                                placeholder="monMotDePasse"
                            />
                        </FormGroup>
                        <Button className={styles.bouton_connexion} type="submit">Changer mon mot de passe</Button>
                    </Form>
                }
                {
                    state.etapeReinitialisation === 4 &&
                    <Button
                        onClick={() => {
                            navigate("/se-connecter")
                        }}
                    >Retourner à la page de connexion</Button>
                }
            </Col>
            <Col lg="4"></Col>
        </Row>
    );
}