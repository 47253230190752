import { Col, Row } from "react-bootstrap";
import styles from "./video-youtube-accueil.module.css";

export default function VideoYouTubeAccueil() {
    return (
        <Row className={"text-center " + styles.conteneur_video_youtube_accueil}>
            <Col lg="3"></Col>
            <Col lg="6">
                <h2 className={styles.titre_video_youtube_accueil}>Avez-vous une plateforme web ?</h2>
                <iframe
                    loading="lazy"
                    className={styles.video_youtube_accueil}
                    src="https://www.youtube-nocookie.com/embed/mlt_mioKPrs?si=fK7xzz8X55cPJXgu&vq=hd1080" 
                    title="Écouter la nouvelle publicité de GreenWood Multimedia" 
                    allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share" 
                    allowFullScreen
                ></iframe>
            </Col>
            <Col lg="3"></Col>
        </Row>
    );
}