import axios from "axios";
import { obtenirValeur } from "../utils/localStorageUtil";

export function obtenir_abonnement_produit_utilisateur(state, setState)
{
    const token = obtenirValeur("token")
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/abonnements/utilisateur",
        {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    abonnements_produits: response.data.contenu.abonnements
                })
                return;
            }
            setState({
                ...state,
                abonnements_produits: []
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                abonnements_produits: []
            })
        })
}