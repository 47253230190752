import axios from "axios";
import { obtenirValeur } from "../utils/localStorageUtil";

export function obtenir_pages(state, setState)
{
    const token = obtenirValeur("token", false);
    const profil = obtenirValeur("profil", true);

    if(profil && profil.role_id === 1)
    {
        axios.get(
            process.env.REACT_APP_NOM_DOMAINE_API + "/pages",
            {
                headers: {
                    Authorization: "Bearer " + token
                }
            }
        )
            .then((response) => {
                if(response.status === 200)
                {
                    setState({
                        ...state,
                        listePages: response.data.contenu
                    })
                    return;
                }
    
                setState({
                    ...state,
                    listePages: []
                })
            })
            .catch((error) => {
                setState({
                    ...state,
                    listePages: []
                })
            })
        return;
    }

    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/pages"
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    listePages: response.data.contenu
                })
                return;
            }

            setState({
                ...state,
                listePages: []
            })
        })
        .catch((error) => {
            setState({
                ...state,
                listePages: []
            })
        })
}

export function obtenir_page_par_id(page_id, state, setState)
{
    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/page",
        {
            "page_id": page_id
        },
        {
            'Content-Type': 'application/json'
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    infosPage: response.data.contenu
                })
                return;
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export function obtenir_page_meta(pathname, state, setState)
{
    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/page",
        {
            "url_canonique": pathname
        }, 
        {
            'Content-Type': 'application/json'
        }
    )
        .then((response) => {
            if(response.data)
            {
                setState({
                    ...state,
                    metaPage: response.data.contenu
                })
                return;
            }
            setState({
                ...state,
                metaPage: []
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                metaPage: []
            })
        })
}

export function ajouter_page(state, setState)
{
    const token = obtenirValeur("token", false)
    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/ajouter-page",
        {
            "nom_page": state.infosPage.nom_page,
            "titre_onglet": state.infosPage.titre_onglet,
            "url_canonique": state.infosPage.url_canonique,
            "description": state.infosPage.description,
            "contenu_page": state.infosPage.contenu_page,
            "produit_id": state.infosPage.produit_id,
            "type_page_id": state.infosPage.type_page_id,
            "role_id": state.infosPage.role_id
        },
        {
            "headers": {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    alertePage: {
                        type: "success",
                        texte: "La page a été ajouté."
                    }
                })
                window.scrollTo(0, 0)
                return;
            }

            setState({
                ...state,
                alertePage: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
            window.scrollTo(0, 0)
        })
        .catch((error) => {
            setState({
                ...state,
                alertePage: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
            window.scrollTo(0, 0)
        })
}

export function modifier_page(state, setState)
{   
    const token = obtenirValeur("token", false)
    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/modifier-page",
        { 
            "id": state.infosPage.id,
            "nom_page": state.infosPage.nom_page,
            "titre_onglet": state.infosPage.titre_onglet,
            "url_canonique": state.infosPage.url_canonique,
            "description": state.infosPage.description,
            "contenu_page": state.infosPage.contenu_page,
            "produit_id": state.infosPage.produit_id,
            "type_page_id": state.infosPage.type_page_id,
            "role_id": state.infosPage.role_id
        },
        {
            "headers": {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    alertePage: {
                        type: "success",
                        texte: "La page a été modifié."
                    }
                })
                window.scrollTo(0, 0)
                return;
            }

            setState({
                ...state,
                alertePage: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
            window.scrollTo(0, 0)
        })
        .catch((error) => {
            setState({
                ...state,
                alertePage: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
            window.scrollTo(0, 0)
        })
}

export function supprimer_page(page_id, state, setState)
{
    const token = obtenirValeur("token", false)
    axios.delete(
        process.env.REACT_APP_NOM_DOMAINE_API + "/supprimer-page",
        {
            data: {
                "id": page_id
            },
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    listePages: null
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })
}