import { Form, FormGroup, FormLabel } from "react-bootstrap";
import styles from "./option.module.css";

export default function OptionNomDomaine(props) {

    return (
        <Form className={styles.conteneur_option}
            onSubmit={(event) => {
                event.preventDefault();
            }}
        >
            <FormGroup>
                <FormLabel>Choissisez votre nom de domaine :</FormLabel>
                <input 
                    className="form-control"
                    type="text"
                    value={props.nomDomaine}
                    placeholder="exemple.com"
                    onChange={(event) => {
                        props.changerNomDomaine(event.target.value)
                    }}
                />
            </FormGroup>
        </Form>
    );
}