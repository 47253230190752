import React, { useEffect } from 'react';
import { Helmet } from "react-helmet";
import Navigation from '../composants/commun/navigation';
import TitrePage from '../composants/commun/titre-page';
import ResultatRecherche from '../composants/recherche/resultat-recherche';
import PiedPage from '../composants/commun/pied-page';
import { useLocation } from 'react-router-dom';
import ReactGA from "react-ga4";

export default function PageRecherche() {
    const location = useLocation();
    const searchTerm = decodeURI(location.search.split("=")[1]);

    useEffect(() => {
      if(process.env.NODE_ENV !== "development")
      {
        ReactGA.initialize(process.env.REACT_APP_CODE_SUIVI_ANALYTICS);
        ReactGA.send(
          { 
            hitType: "pageview", 
            page: location.pathname, 
            title: "GreenWood Multimedia | Recherche"
          } 
        );
      }
    }, [location])

    return (
      <>
        <Helmet>
          <title>GreenWood Multimedia | Recherche</title>
          <link rel="canonical" href={process.env.REACT_APP_NOM_DOMAINE + "/recherche"} />
          <meta name="description" content="Rechercher sur le site web GreenWood Multimedia." />
        </Helmet>
        <Navigation />
        <TitrePage>
            {
                searchTerm && searchTerm !== 'undefined' ?
                "Résultat(s) de votre recherche : " + searchTerm :
                "Aucun résultat pour votre recherche"
            }
        </TitrePage>
        <ResultatRecherche />
        <PiedPage />
      </>
    );
  }