import { Alert, Col, Row, Button, Card, Table } from "react-bootstrap";
import styles from "./tableau-de-bord.module.css";
import { useEffect, useState } from "react";
import { obtenir_transaction_utilisateur } from "../../services/transactionService";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import { sauvegarderValeur } from "../../utils/localStorageUtil";
import { obtenir_abonnement_produit_utilisateur } from "../../services/abonnementProduitService";

export default function TableauDeBord() {

    const [state, setState] = useState({
        totalCompte: 0,
        totalCompteCalcule: false,
        factures: null,
        abonnements_produits: null,
        minFacture: 0,
        minAbonnement: 0
    })
    const navigate = useNavigate();

    useEffect(() => {
        !state.factures && obtenir_transaction_utilisateur(state, setState)
        !state.abonnements_produits && obtenir_abonnement_produit_utilisateur(state, setState)
    }, [state])

    useEffect(() => {
        if(state.factures && state.factures.length > 0 && !state.totalCompteCalcule)
        {
            let totalCompte = 0;
            let factures = state.factures;
            factures.forEach((facture) => {
                if(facture.statut_transaction_id === 1 || facture.statut_transaction_id === 2)
                {
                    totalCompte += facture.montant_total_transaction
                }
            })
            setState({
                ...state,
                totalCompteCalcule: true,
                totalCompte: totalCompte
            })
        }
    }, [state])

    function afficherStatutTransaction(statut_transaction_id)
    {
        switch(statut_transaction_id)
        {
            case 1:
                return "À payer"
            case 2:
                return "En retard"
            case 3:
                return "Terminé"
            case 4:
                return "Annulé"
            default:
                return "À payer"
        }
    }

    return (
        <Row className={styles.conteneur_tableau_de_bord + " text-center"}>
            <Col lg="2"></Col>
            <Col lg="4">
                <h2>Solde de votre compte</h2>
                <p className={styles.conteneur_solde}>{state.totalCompte + " "}$</p>
                <h2>Vos factures</h2>
                {
                    state.factures &&
                    state.factures.length > 3 &&
                    <Button
                        className={styles.bouton_liste_facture}
                        disabled={state.minFacture === 0}
                        onClick={() => {
                            if(state.minFacture > 0)
                            {
                                setState({
                                    ...state,
                                    minFacture: state.minFacture - 3
                                })
                            }
                        }}
                    >Précèdent</Button>
                }
                {
                    state.factures &&
                    state.factures.length > 3 &&
                    <Button
                        className={styles.bouton_liste_facture}
                        disabled={state.minFacture > state.factures.length - 3}
                        onClick={() => {
                            if(state.minFacture < state.factures.length - 3)
                            {
                                setState({
                                    ...state,
                                    minFacture: state.minFacture + 3
                                })
                            }
                        }}
                    >Suivant</Button>
                }
                {
                    (!state.factures ||
                    (state.factures &&
                    state.factures.length === 0)) &&
                    <Alert className={styles.aucune_facture}>Aucune facture à afficher.</Alert>
                }
                {
                    state.factures &&
                    state.factures.length > 0 &&
                    state.factures
                    .slice(state.minFacture, state.minFacture + 3)
                    .map((facture) => {
                        return (
                            <Card className={styles.factures_tab_bord}>
                                <Card.Body>
                                    <Card.Title>Facture du {format(new Date(facture.date_creation), "yyyy-MM-dd")}</Card.Title>
                                    <Card.Text>Montant total : {facture.montant_total_transaction} $</Card.Text>
                                    <Card.Text>Statut : {afficherStatutTransaction(facture.statut_transaction_id)}</Card.Text>
                                    <Button 
                                        className={styles.bouton_action_facture}
                                        onClick={() => {
                                            navigate("/voir-facture?uuid=" + facture.uuid)
                                        }}
                                        variant="primary"
                                    >Voir la facture</Button>
                                    {
                                        (facture.statut_transaction_id === 1 ||
                                        facture.statut_transaction_id === 2) &&
                                        <Button 
                                            onClick={() => {
                                                sauvegarderValeur("panier", facture.produits_transactions, true)
                                                navigate("/effectuer-commande?uuid=" + facture.uuid)
                                            }}
                                            variant="primary"
                                        >Payer la facture</Button>
                                    }
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </Col>
            <Col lg="1"></Col>
            <Col lg="4">
                <h2>Vos abonnements produits</h2>
                {
                    state.abonnements_produits &&
                    state.abonnements_produits.length > 3 &&
                    <Button
                        className={styles.bouton_liste_facture}
                        disabled={state.minAbonnement === 0}
                        onClick={() => {
                            if(state.minAbonnement > 0)
                            {
                                setState({
                                    ...state,
                                    minAbonnement: state.minAbonnement - 3
                                })
                            }
                        }}
                    >Précèdent</Button>
                }
                {
                    state.abonnements_produits &&
                    state.abonnements_produits.length > 3 &&
                    <Button
                        className={styles.bouton_liste_facture}
                        disabled={state.minAbonnement > state.abonnements_produits.length - 3}
                        onClick={() => {
                            if(state.minAbonnement < state.abonnements_produits.length - 3)
                            {
                                setState({
                                    ...state,
                                    minAbonnement: state.minAbonnement + 3
                                })
                            }
                        }}
                    >Suivant</Button>
                }
                <Row>
                {
                    (!state.abonnements_produits ||
                    (state.abonnements_produits &&
                    state.abonnements_produits.length === 0)) &&
                    <Alert className={styles.aucune_facture}>Aucun service à afficher.</Alert>
                }
                {
                    state.abonnements_produits &&
                    state.abonnements_produits.length > 0 &&
                    state.abonnements_produits
                    .slice(state.minAbonnement, state.minAbonnement + 3)
                    .map((abonnement_produit) => {
                        return (
                            <Col lg="12">
                                <Card className={styles.abonnement_produit}>
                                    <Card.Body>
                                        <Card.Title>{abonnement_produit.nom_produit}</Card.Title>
                                        <Table variant="dark" striped bordered hover>
                                            <tbody>
                                                {
                                                    abonnement_produit.produit_id === 3 &&
                                                    <tr>
                                                        <th>Domaine associé</th>
                                                        <td>{abonnement_produit.nom_domaine}</td>
                                                    </tr>
                                                }
                                                <tr>
                                                    <th>Date de départ</th>
                                                    <td>{abonnement_produit.date_depart}</td>
                                                </tr>
                                                <tr>
                                                    <th>Date de renouvellement</th>
                                                    <td>{abonnement_produit.date_renouvellement}</td>
                                                </tr>
                                                <tr>
                                                    <th>Terme</th>
                                                    <td>{abonnement_produit.terme + " "} an(s)</td>
                                                </tr>
                                                <tr>
                                                    <th>Statut</th>
                                                    <td>{abonnement_produit.est_actif ? "En vigueur" : "Non actif"}</td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })
                }
                </Row>
            </Col>
            <Col lg="2"></Col>
        </Row>
    );
}