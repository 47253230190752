import axios from "axios";
import validator from "validator";
import { obtenirValeur, sauvegarderValeur, supprimerValeur } from "../utils/localStorageUtil";

export function connexion(state, setState, navigate)
{
    if(!state.infosConnexion.courriel)
    {
        setState({
            ...state,
            alerteConnexion: {
                type: "danger",
                texte: "Veuillez fournir votre courriel."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!validator.isEmail(state.infosConnexion.courriel))
    {
        setState({
            ...state,
            alerteConnexion: {
                type: "danger",
                texte: "Veuillez fournir votre courriel du format suivant : 'johndoe@domaine.com'."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!state.infosConnexion.mot_de_passe)
    {
        setState({
            ...state,
            alerteConnexion: {
                type: "danger",
                texte: "Veuillez fournir votre mot_de_passe."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }

    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/authentification/connexion",
        {
            recaptcha_token: state.recaptcha_token,
            courriel: state.infosConnexion.courriel,
            mot_de_passe: state.infosConnexion.mot_de_passe
        }
    )
        .then((responseConnexion) => {
            if(responseConnexion.status === 200)
            {
                axios.get(
                    process.env.REACT_APP_NOM_DOMAINE_API + "/utilisateur/profil",
                    {
                        headers: {
                            "Authorization": "Bearer " + responseConnexion.data.contenu.token
                        }
                    }
                )
                    .then((responseProfil) => {
                        if(responseProfil.status === 200)
                        {
                            sauvegarderValeur("token", responseConnexion.data.contenu.token)
                            sauvegarderValeur("profil", responseProfil.data.contenu, true)
                            navigate("/tableau-de-bord")
                            return;
                        }
                        setState({
                            ...state,
                            alerteConnexion: {
                                type: "danger",
                                texte: responseProfil.data.message[0]
                            }
                        })
                        document.getElementById('alerte_contact').scrollIntoView({
                            behavior: 'smooth'
                        });
                    })
                    .catch((error) => {
                        setState({
                            ...state,
                            alerteConnexion: {
                                type: "danger",
                                texte: error.response.data.message[0]
                            }
                        })
                        document.getElementById('alerte_contact').scrollIntoView({
                            behavior: 'smooth'
                        });
                    })
                return;
            }
            setState({
                ...state,
                alerteConnexion: {
                    type: "danger",
                    texte: responseConnexion.data.message[0]
                }
            })
            document.getElementById('alerte_contact').scrollIntoView({
                behavior: 'smooth'
            });
        })
        .catch((error) => {
            setState({
                ...state,
                alerteConnexion: {
                    type: "danger",
                    texte: error.response.data.message[0]
                }
            })
            document.getElementById('alerte_contact').scrollIntoView({
                behavior: 'smooth'
            });
            console.log(error)
        })
}

export function verifier_token(navigate, location)
{
    const token = obtenirValeur("token", false);
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/authentification/verifier-connexion",
        {
            "headers": {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200 && location === "/se-connecter")
            {
                navigate("/tableau-de-bord")
            }
            if(response.status !== 200 && location !== "/se-connecter")
            {
                supprimerValeur("token")
                supprimerValeur("profil")
                navigate("/se-connecter")
            }
        })
        .catch((error) => {
            console.log(error)
            if(location !== "/se-connecter")
            {
                supprimerValeur("token")
                supprimerValeur("profil")
                navigate("/se-connecter")
            }
        })
}

export function envoyer_courriel_reinitialisation(state, setState)
{
    if(!state.infosRenitialisation.courriel)
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "warning",
                texte: "Veuillez remplir le courriel."
            }
        })
        return;
    }
    if(state.infosRenitialisation.courriel && !validator.isEmail(state.infosRenitialisation.courriel))
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "warning",
                texte: "Veuillez remplir le courriel avec le format 'johndoe@domaine.com'."
            }
        })
        return;
    }

    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/courriel/reinitialisation-mot-de-passe",
        {
            "courriel": state.infosRenitialisation.courriel,
            "recaptcha_token": state.recaptcha_token
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    etapeReinitialisation: 2,
                    alerteReinitialisation: {
                        type: "success",
                        texte: "Un courriel vous a été envoyé avec un code de réinitialisation. Veuillez entrer celui-ci afin de pouvoir modifier votre mot de passe."
                    }
                })
                return;
            }

            setState({
                ...state,
                alerteReinitialisation: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
        .catch((error) => {
            setState({
                ...state,
                alerteReinitialisation: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
}

export function verifier_code_reinitialisation(state, setState)
{
    if(!state.infosRenitialisation.courriel)
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "danger",
                texte: "Une erreur inattendue est survenue."
            }
        })
        return;
    }
    if(!state.infosRenitialisation.code_reinitialisation)
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "warning",
                texte: "Veuillez fournir le code de réinitialisation."
            }
        })
        return;
    }

    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/authentification/verifier-code-reinitialisation",
        {
            "courriel": state.infosRenitialisation.courriel,
            "code_reinitialisation": state.infosRenitialisation.code_reinitialisation,
            "recaptcha_token": state.recaptcha_token
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    etapeReinitialisation: 3,
                    alerteReinitialisation: {
                        type: "success",
                        texte: "Le code de réinitialisation a été validé. Veuillez changer votre mot de passe."
                    }
                })
                return;
            }

            setState({
                ...state,
                alerteReinitialisation: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
        .catch((error) => {
            setState({
                ...state,
                alerteReinitialisation: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
}

export function changer_mot_de_passe(state, setState)
{
    if(!state.infosRenitialisation.courriel)
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "danger",
                texte: "Une erreur inattendue est survenue."
            }
        })
        return;
    }
    if(!state.infosRenitialisation.code_reinitialisation)
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "danger",
                texte: "Une erreur inattendue est survenue."
            }
        })
        return;
    }
    if(!state.infosRenitialisation.mot_de_passe)
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "warning",
                texte: "Veuillez fournir votre nouveau mot de passe."
            }
        })
        return;
    }
    if(!state.infosRenitialisation.nouveau_mot_de_passe)
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "warning",
                texte: "Veuillez répéter à nouveau votre nouveau mot de passe."
            }
        })
        return;
    }
    if(state.infosRenitialisation.nouveau_mot_de_passe !== state.infosRenitialisation.mot_de_passe)
    {
        setState({
            ...state,
            alerteReinitialisation: {
                type: "warning",
                texte: "Veuillez vous assurer que les deux mot de passe concordent."
            }
        })
        return;
    }

    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/authentification/changer-mot-de-passe",
        {
            "courriel": state.infosRenitialisation.courriel,
            "code_reinitialisation": state.infosRenitialisation.code_reinitialisation,
            "mot_de_passe": state.infosRenitialisation.mot_de_passe,
            "recaptcha_token": state.recaptcha_token
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    etapeReinitialisation: 4,
                    alerteReinitialisation: {
                        type: "success",
                        texte: "Vous avez bel et bien changé votre mot de passe."
                    }
                })
                return;
            }

            setState({
                ...state,
                alerteReinitialisation: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
        .catch((error) => {
            setState({
                ...state,
                alerteReinitialisation: {
                    type: "danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
}