import axios from "axios";
import { obtenirValeur } from "../utils/localStorageUtil";

export function obtenir_tous_les_types_page(state, setState)
{
    const token = obtenirValeur("token", false)
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/types-page",
        {
            "headers": {
                "Authorization": "Bearer " + token 
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    typesPage: response.data.contenu
                })
                return;
            }

            setState({
                ...state,
                typesPage: []
            })
        })
        .catch((error) => {
            setState({
                ...state,
                typesPage: []
            })
        })
}