import axios from "axios";

export function obtenir_menu(nomMenu, state, setState)
{
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/menu/" + nomMenu
    )
        .then((response) => {
            if(response.data)
            {
                setState({
                    ...state,
                    menu: response.data.contenu
                })
                return;
            }
            setState({
                ...state,
                menu: []
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                menu: []
            })
        })
}