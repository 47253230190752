import axios from "axios";

export function obtenir_tous_les_produits(state, setState)
{
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/produits"
    )
        .then((response) => {
            if(response.data)
            {
                setState({
                    ...state,
                    produits: response.data.contenu.produits
                })
                return;
            }
            setState({
                ...state,
                produit: []
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                produits: []
            })
        })
}

export function obtenir_produit_par_id(produit_id, state, setState)
{
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/produits/" + produit_id
    )
        .then((response) => {
            if(response.data)
            {
                setState({
                    ...state,
                    elementAjoutePanier: false,
                    produit: response.data.contenu.produit
                })
                return;
            }
            setState({
                ...state,
                elementAjoutePanier: false,
                produit: []
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                elementAjoutePanier: false,
                produit: []
            })
        })
}