import { Col, Row } from "react-bootstrap";
import styles from "./pied-copyright.module.css";
import packageJSON from "../../../package.json";

export default function PiedCopyright() {

    return (
        <Row className={styles.pied_copyright + " text-center"}>
            <Col lg="2"></Col>
            <Col lg="4">
                <p>GreenWood Multimedia © {new Date().getFullYear().toString()} - Tous droits réservés. Version {packageJSON.version}</p>
            </Col>
            <Col lg="4">
                <a
                    className={styles.nav_link_pied_page}
                    href={"/politique-de-confidentialite"}
                >Politique de confidentialité</a>
            </Col>
            <Col lg="2"></Col>
        </Row>
    );
}