import { Card, Col, Row } from "react-bootstrap";
import styles from "./liste-projets.module.css";
import { useEffect, useState } from "react";
import { obtenir_projets } from "../../services/projetService";
import { creerCleUnique } from "../../utils/utilList";

export default function ListeProjets() {

    const [state, setState] = useState({
        listeProjets: null
    })

    useEffect(() => {
        !state.listeProjets && obtenir_projets(state, setState)
    }, [state])

    return (
        <Row className={styles.conteneur_liste_article + " text-center"}>
            <Col lg="1"></Col>
            <Col lg="10">
                <Row>
                    {
                        state.listeProjets &&
                        state.listeProjets.length > 0 &&
                        state.listeProjets
                        .sort((projetA, projetB) => {
                            return new Date(projetB.moment_creation) - new Date(projetA.moment_creation) > 0
                        })
                        .map((projet) => {
                            return (
                                <Col key={creerCleUnique()} className={styles.conteneur_colonne_article} lg="3">
                                    <a 
                                        className={styles.lien_article}
                                        href={projet.url_canonique}
                                    >
                                        <Card>
                                            <Card.Img 
                                                variant="img-top"
                                                src={process.env.REACT_APP_NOM_DOMAINE_API + projet.url_image}
                                            />
                                            <Card.Title className={styles.titre_article}>{projet.nom_page}</Card.Title>
                                            <Card.Body className={styles.conteneur_contenu_article}>
                                                {
                                                    projet.description &&
                                                    <Card.Text className={styles.texte_projet}>{projet.description}</Card.Text>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Col>
            <Col lg="1"></Col>
        </Row>
    );
}