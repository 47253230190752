import { Card, Col, Row } from "react-bootstrap";
import styles from "./principes-gw.module.css";


export default function PrincipesGw() {
    return (
        <Row className={styles.conteneur_principes + " text-center"}>
            <Col lg="1"></Col>
            <Col lg="2">
                <img 
                    loading="lazy"
                    className="img-fluid"
                    src={process.env.REACT_APP_NOM_DOMAINE_API + "/images/a-propos/christopher-boisvert-proprietaire-de-greenwood-multimedia.jpeg"}
                    alt="Christopher Boisvert, propriétaire de l'entreprise GreenWood Multimedia."
                />
                <figcaption className={styles.texte_image_cb}>Christopher Boisvert, propriétaire de l'entreprise GreenWood Multimedia.</figcaption>
            </Col>
            <Col lg="8">
                <p className={styles.texte_principe}>GreenWood Multimedia se positionne en tant qu'entreprise spécialisée dans les services informatiques opérant dans la province du Québec. Forte de sa clientèle diversifiée issue de divers secteurs économiques, la mission de GreenWood Multimedia consiste à propulser les entreprises québécoises vers l'avenir numérique. Au centre de cette entreprise, Christopher Boisvert, fondateur de GreenWood Multimedia, qui a lancé cette entreprise en 2017.</p>
                <h3 className={styles.titre_principle_global}>Trois principles fondamentaux</h3>
                <Row>
                    <Col lg="4">
                        <Card className={styles.carte_principe}>
                            <Card.Img 
                                variant="top" 
                                src={process.env.REACT_APP_NOM_DOMAINE_API + "/images/a-propos/eoliennes-dans-un-montagne-eneige.jpg"}
                            />
                            <Card.Body>
                                <Card.Title className={styles.titre_principe}>Principe écologique</Card.Title>
                                <Card.Text className={styles.texte_principe}>GreenWood Multimedia effectue des efforts et à l'intention de réduire l'empreinte carbone généré par nos services, car pour GreenWood Multimedia, il est fondamental de réduire ses émissions à effet de serre selon <a href="https://www.ipcc.ch/languages-2/francais/">les objectifs du GIEC</a> qui ont été publié en 2022.</Card.Text>
                                <Card.Text className={styles.texte_principe}>Afin de contribuer à cet objectif, toute la facturation/documentation de GreenWood Multimedia a été converti du format papier au format numérique.</Card.Text>
                                <Card.Text className={styles.texte_principe}>De plus, notre fournisseur d'hébergement web canadien est à 100% alimenté par des sources renouvelables d'énergies. En effet, celui-ci est certifié par <a href="https://www.b-e-f.org/">BEF</a> et <a href="https://www.green-e.org/">Green-E</a>.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className={styles.carte_principe}>
                            <Card.Img 
                                variant="top" 
                                src={process.env.REACT_APP_NOM_DOMAINE_API + "/images/a-propos/deux-mains-se-serrant-la-main-devant-une-maison.jpg"}
                            />
                            <Card.Body>
                                <Card.Title className={styles.titre_principe}>Principe de solidarité</Card.Title>
                                <Card.Text className={styles.texte_principe}>Chez GreenWood Multimedia, nous nous engageons à soutenir le bien-être social du Québec et du Canada. Dans cette optique, nous proposons des tarifs avantageux aux organismes à but non lucratif désireux de créer leur propre plateforme web.</Card.Text>
                                <Card.Text>Par exemple, chaque année, nous offrons à l'organisme Les Jardins du Coeur un hébergement gratuit sur nos serveurs. Cette initiative témoigne de notre volonté de soutenir activement les initiatives sociales et communautaires qui ont un impact positif. En encourageant la présence en ligne de ces organisations, nous contribuons à renforcer leur visibilité et leur efficacité dans la réalisation de leurs missions altruistes.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg="4">
                        <Card className={styles.carte_principe}>
                            <Card.Img 
                                variant="top" 
                                src={process.env.REACT_APP_NOM_DOMAINE_API + "/images/a-propos/ingenieur-logiciel-en-face-de-son-ordinateur.jpg"}
                            />
                            <Card.Body>
                                <Card.Title className={styles.titre_principe}>Principe d'innovation</Card.Title>
                                <Card.Text className={styles.texte_principe}>Chez GreenWood Multimedia, nous nous engageons à intégrer les dernières avancées technologiques dans les systèmes informatiques de nos clients pour générer un impact réel et significatif sur la société. Notre approche proactive vise à offrir rapidement les avantages commerciaux des nouvelles technologies, renforçant ainsi l'efficacité opérationnelle et la compétitivité de nos clients. </Card.Text>
                                <Card.Text className={styles.texte_principe}>Grâce à notre expertise en recherche et à nos partenariats stratégiques, nous fournissons des solutions adaptées aux besoins spécifiques de nos clients, contribuant ainsi à leur succès et à la transformation numérique de la société.</Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Col>
            <Col lg="1"></Col>
        </Row>
    );
}