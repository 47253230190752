import { Alert, Card, Col, Row } from "react-bootstrap";
import styles from "./liste-article.module.css";

import { useEffect, useState } from "react";
import { obtenir_articles } from "../../services/articleService";
import { format } from "date-fns";
import { creerCleUnique } from "../../utils/utilList";

export default function ListeArticle() {

    const [state, setState] = useState({
        listeArticle: null
    })

    useEffect(() => {
        !state.listeArticle && obtenir_articles(state, setState)
    }, [state])

    return (
        <Row className={styles.conteneur_liste_article + " text-center"}>
            <Col lg="1"></Col>
            <Col lg="10">
                <Row>
                    {
                        state.listeArticle &&
                        state.listeArticle.length === 0 &&
                        <Alert variant="warning">Aucun article à afficher.</Alert>
                    }
                    {
                        state.listeArticle &&
                        state.listeArticle.length > 0 &&
                        state.listeArticle.map((article) => {
                            return (
                                <Col key={creerCleUnique()} lg="3">
                                    <a 
                                        className={styles.lien_article}
                                        href={article.url_canonique}
                                    >
                                        <Card>
                                            {
                                                article.url_image &&
                                                <Card.Img 
                                                    variant="img-top"
                                                    src={process.env.REACT_APP_NOM_DOMAINE_API + article.url_image}
                                                />
                                            }
                                            <Card.Title className={styles.titre_article}>{article.nom_page}</Card.Title>
                                            <Card.Body className={styles.conteneur_contenu_article}>
                                                {
                                                    article.description &&
                                                    <Card.Text>{article.description}</Card.Text>
                                                }
                                                {
                                                    article.moment_creation &&
                                                    <Card.Text>Publié le {format(new Date(article.moment_creation), "yyyy-MM-dd")}</Card.Text>
                                                }
                                            </Card.Body>
                                        </Card>
                                    </a>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Col>
            <Col lg="1"></Col>
        </Row>
    );
}