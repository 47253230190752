export function obtenirValeur(cle, json)
{
    if(json) return JSON.parse(localStorage.getItem(cle))
    return localStorage.getItem(cle)
}

export function sauvegarderValeur(cle, valeur, json)
{
    if(json)
    {
        const nouvelle_valeur_json = JSON.stringify(valeur)
        localStorage.setItem(cle, nouvelle_valeur_json)
        return;
    }

    localStorage.setItem(cle, valeur)
}

export function supprimerValeur(cle)
{
    localStorage.removeItem(cle)
}