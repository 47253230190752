import {v4 as uuidv4} from 'uuid';

/**
 * Crée une clé unique.
 * @example
 * list.map(item => {
 *     return <h1 key={creerCleUnique()}></h1>
 * })
 * @returns Clé unique
 */
 export function creerCleUnique()
 {
    return uuidv4();
}