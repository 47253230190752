import { Form, FormGroup, FormLabel, FormSelect } from "react-bootstrap";
import styles from "./option.module.css";

export default function OptionDuree(props) {

    return (
        <Form 
            className={styles.conteneur_option}
            onSubmit={(event) => {
                event.preventDefault();
            }}
        >
            <FormGroup>
                <FormLabel>Choissisez votre durée :</FormLabel>
                <FormSelect
                    onChange={(event) => {
                        props.changerDuree(Number(event.target.value))
                    }}
                >
                    <option value={1}>1 an</option>
                    <option value={2}>2 an(s)</option>
                    <option value={3}>3 an(s)</option>
                </FormSelect>
            </FormGroup>
        </Form>
    );
}