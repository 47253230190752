import { Alert, Col, Row } from "react-bootstrap";
import styles from "./contenu-page.module.css";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { obtenir_page_meta } from "../../services/pageService";

export default function ContenuPage() {

    const [state, setState] = useState({
        metaPage: null
    })
    const location = useLocation();

    useEffect(() => {
        if(state.metaPage && state.metaPage.url_canonique !== location.pathname)
        {
            obtenir_page_meta(location.pathname, state, setState)
        }
        !state.metaPage && obtenir_page_meta(location.pathname, state, setState)
    }, [state, location.pathname])

    return (
        <Row className={styles.conteneur_liste_article + " text-center"}>
            <Col lg="3"></Col>
            <Col lg="6">
                {
                    state.metaPage &&
                    state.metaPage.url_image &&
                    state.metaPage.type_page_id === 5 &&
                    <img 
                        className={"img-fluid " + styles.image_portfolio}
                        src={process.env.REACT_APP_NOM_DOMAINE_API + state.metaPage.url_image}
                        alt={state.metaPage.description}
                    />
                }
                {
                    state.metaPage &&
                    !state.metaPage.contenu_page &&
                    <Row>
                        <Col lg="3"></Col>
                        <Col lg="6">
                            <Alert className={styles.alerte_erreur_contenu}>Le contenu de la page que vous recherchez n'existe pas.</Alert>
                        </Col>
                        <Col lg="3"></Col>
                    </Row>
                }
                {
                    state.metaPage &&
                    state.metaPage.contenu_page &&
                    <div
                        className={styles.conteneur_contenu_page}
                        dangerouslySetInnerHTML={
                            {
                                __html: state.metaPage.contenu_page
                                .replaceAll("<p><br></p>", '')
                                .replaceAll('<p class="ql-align-justify"><br></p>', '')
                            }
                        } 
                    />
                }
            </Col>
            <Col lg="3"></Col>
        </Row>
    );
}