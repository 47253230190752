import { Col, Row } from "react-bootstrap";
import styles from "./section-avantage.module.css";
import { BsClock, BsCash } from "react-icons/bs";
import { BiPieChart } from "react-icons/bi";

export default function SectionAvantageAccueil() {
    return (
        <Row className={styles.conteneur_section_avantage_accueil + " text-center"}>
            <Col lg="2"></Col>
            <Col lg="8">
                <h2 className={styles.titre_section_avantage_accueil}>Profitez de nos avantages distinctifs</h2>
                <Row>
                    <Col className={styles.conteneur_avantage_accueil} lg="4">
                        <BsClock className={styles.icone_avantage_accueil} />
                        <h3 className={styles.titre_sous_section_avantage_accueil}>Expertise numérique accumulée depuis 7 ans</h3>
                        <p className={styles.texte_sous_section_avantage_accueil}>Depuis sept ans, GreenWood Multimedia a acquis une expertise solide en développement web, développement mobile, hébergement web et cybersécurité. En choisissant GreenWood Multimedia, vous bénéficierez de cette expertise pour rendre vos solutions numériques plus agiles et efficaces.</p>
                    </Col>
                    <Col className={styles.conteneur_avantage_accueil} lg="4">
                        <BsCash className={styles.icone_avantage_accueil} />
                        <h3 className={styles.titre_sous_section_avantage_accueil}>Clientèle diversifiée dans plusieurs marchés</h3>
                        <p className={styles.texte_sous_section_avantage_accueil}>Notre entreprise a réalisé des projets dans divers secteurs d'affaires tels que l'événementiel, la photographie, la construction, la santé, et bien d'autres. Nous adaptons nos solutions technologiques aux réalités spécifiques de votre entreprise.</p>
                    </Col>
                    <Col className={styles.conteneur_avantage_accueil} lg="4">
                        <BiPieChart className={styles.icone_avantage_accueil} />
                        <h3 className={styles.titre_sous_section_avantage_accueil}>Optimisez votre suivi utilisateur avec nos solutions technologiques</h3>
                        <p className={styles.texte_sous_section_avantage_accueil}>Nos solutions technologiques vous permettront de suivre l’utilisation de vos utilisateurs, quelle que soit la plateforme que vous utilisez. Nous vous aiderons à collecter des informations stratégiques essentielles à la conception de vos indicateurs de performance.</p>
                    </Col>
                </Row>
            </Col>
            <Col lg="2"></Col>
        </Row>
    );
}