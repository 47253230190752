import axios from "axios";

export function obtenir_articles(state, setState)
{
    axios(
        process.env.REACT_APP_NOM_DOMAINE_API + "/pages/articles",
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    listeArticle: response.data.contenu
                })
                return;
            }
            setState({
                ...state,
                listeArticle: []
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                listeArticle: []
            })
        })
}