import axios from "axios";
import validator from "validator";

export function envoyer_message_courriel(state, setState)
{
    if(!state.informationContact.prenom)
    {
        setState({
            ...state,
            alerteContact: {
                type: "danger",
                texte: "Veuillez remplir le prénom."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!state.informationContact.nom)
    {
        setState({
            ...state,
            alerteContact: {
                type: "danger",
                texte: "Veuillez remplir le nom."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!state.informationContact.courriel)
    {
        setState({
            ...state,
            alerteContact: {
                type: "danger",
                texte: "Veuillez remplir le courriel."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!validator.isEmail(state.informationContact.courriel))
    {
        setState({
            ...state,
            alerteContact: {
                type: "danger",
                texte: "Veuillez remplir le courriel avec le format 'john@domaine.com'."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!state.informationContact.telephone)
    {
        setState({
            ...state,
            alerteContact: {
                type: "danger",
                texte: "Veuillez remplir le telephone."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!state.informationContact.nomEntreprise)
    {
        setState({
            ...state,
            alerteContact: {
                type: "danger",
                texte: "Veuillez remplir le nom de votre entreprise."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!state.informationContact.sujet)
    {
        setState({
            ...state,
            alerteContact: {
                type: "danger",
                texte: "Veuillez remplir le sujet."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }
    if(!state.informationContact.message)
    {
        setState({
            ...state,
            alerteContact: {
                type: "danger",
                texte: "Veuillez remplir le message."
            }
        })
        document.getElementById('alerte_contact').scrollIntoView({
            behavior: 'smooth'
        });
        return;
    }

    axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/courriel/contact",
        {
            recaptcha_token: state.recaptcha_token,
            prenom: state.informationContact.prenom,
            nom: state.informationContact.nom,
            courriel: state.informationContact.courriel,
            sujet: state.informationContact.sujet,
            telephone: state.informationContact.telephone,
            nom_entreprise: state.informationContact.nomEntreprise,
            message: state.informationContact.message
        },
        {
            'Content-Type': 'application/json'
        }
    )
        .then((response) => {
            setState({
                ...state,
                alerteContact: {
                    type: "danger",
                    texte: response.data.message[0]
                }
            })
            document.getElementById('alerte_contact').scrollIntoView({
                behavior: 'smooth'
            });
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                alerteContact: {
                    type: "danger",
                    texte: error.response.data.message[0]
                }
            })
            document.getElementById('alerte_contact').scrollIntoView({
                behavior: 'smooth'
            });
        })
}