import {  Alert, Button, Card, Col, Row } from "react-bootstrap";
import styles from "./resultat-recherche.module.css";
import { useLocation, useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { obtenir_pages } from "../../services/pageService";

export default function ResultatRecherche() {

    let location = useLocation();
    const termeUtilise = decodeURI(location.search.split("=")[1]);
    const [state, setState] = useState({
        listePages: null
    })
    const navigate = useNavigate();

    useEffect(() => {
        !state.listePages && obtenir_pages(state, setState);
    }, [state])

    return (
        <Row className={styles.conteneur_titre_page}>
            <Col lg="2"></Col>
            <Col lg="8">
                {
                    state.listePages &&
                    state.listePages.length === 0 &&
                    <Alert className={styles.resultat_recherche} variant="danger">Une erreur inattendue est survenue.</Alert>
                }
                {
                    state.listePages &&
                    state.listePages.length > 0 &&
                    state.listePages
                    .filter((page) => {
                        if(termeUtilise && page.nom_page.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                        if(termeUtilise && page.url_canonique.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                        if(termeUtilise && page.description && page.description.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                        return false;
                    })
                    .length > 0 &&
                    <Row className={styles.conteneur_resultat_recherche}>
                        {
                            state.listePages
                            .filter((page) => {
                                if(termeUtilise && page.nom_page.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                                if(termeUtilise && page.url_canonique.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                                if(termeUtilise && page.description && page.description.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                                return false;
                            })
                            .map((page) =>  {
                                return (
                                    <Col lg="6">
                                        <Card className={styles.resultat_recherche}>
                                            <Card.Body>
                                                <Card.Title>{page.nom_page}</Card.Title>
                                                <Card.Text>{page.description}</Card.Text>
                                            </Card.Body>
                                            <Button
                                                className={styles.bouton_voir_page}
                                                onClick={() => {
                                                    navigate(page.url_canonique)
                                                }}
                                                variant="primary"
                                            >Voir la page</Button>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }
                <Row className={styles.conteneur_resultat_recherche}>
                    <Col lg="3"></Col>
                    <Col lg="6">
                        {
                            state.listePages &&
                            state.listePages.length > 0 &&
                            state.listePages
                            .filter((page) => {
                                if(termeUtilise && page.nom_page.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                                if(termeUtilise && page.url_canonique.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                                if(termeUtilise && page.description && page.description.toLowerCase().includes(termeUtilise.toLowerCase())) return true;
                                return false;
                            })
                            .length === 0 &&
                            <Alert className={styles.alerte_resultat}>Aucun résultat à votre recherche.</Alert>
                        }
                    </Col>
                    <Col lg="3"></Col>
                </Row>
            </Col>
            <Col lg="2"></Col>
        </Row>
    );
}