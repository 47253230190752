import { Alert, Button, Col, Form, FormGroup, Row, Table } from "react-bootstrap";
import styles from "./liste-pages.module.css";
import { useEffect, useState } from "react";
import { obtenir_pages, supprimer_page } from "../../services/pageService";
import { creerCleUnique } from "../../utils/utilList";
import { obtenirValeur } from "../../utils/localStorageUtil";
import { useNavigate } from "react-router";

export default function ListePages() {

    const [state, setState] = useState({
        listePages: null,
        pageRecherche: "",
        indexPageMin: 0
    })
    const profil = obtenirValeur("profil", true);
    const navigate = useNavigate();

    useEffect(() => {
        !state.listePages && profil.role_id === 1 && obtenir_pages(state, setState);
    }, [state, profil])

    return (
        <Row className={styles.conteneur_form_connexion + " text-center"}>
            <Col lg="2"></Col>
            <Col lg="8">
                {
                    profil && 
                    profil.role_id === 1 &&
                    <>
                        {
                            state.listePages &&
                            state.listePages.length === 0 &&
                            <Alert variant="warning">Aucune page n'a pas être chargé.</Alert>
                        }
                        <Row className={styles.form_affichage_page}>
                            <Col lg="2"></Col>
                            <Col lg="2">
                                <Button
                                    disabled={state.indexPageMin === 0}
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            indexPageMin: state.indexPageMin - 5
                                        })
                                    }}
                                >Précédent</Button>
                            </Col>
                            <Col lg="4">
                                <Form>
                                    <Button
                                        onClick={() => {
                                            navigate("/ajouter-page")
                                        }}
                                    >Ajouter une page</Button>
                                    <FormGroup>
                                        <input 
                                            className="form-control"
                                            type="text"
                                            value={state.pageRecherche}
                                            onChange={(event) => {
                                                setState({
                                                    ...state,
                                                    pageRecherche: event.target.value
                                                })
                                            }}
                                            placeholder="Rechercher une page..."
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                            <Col lg="2">
                                <Button
                                    disabled={state.listePages && state.indexPageMin + 5 >= state.listePages.length}
                                    onClick={() => {
                                        setState({
                                            ...state,
                                            indexPageMin: state.indexPageMin + 5
                                        })
                                    }}
                                >Suivant</Button>
                            </Col>
                        </Row>
                        <Table className={styles.table_liste_pages} striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Url canonique</th>
                                    <th>Nom</th>
                                    <th>Description</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    state.listePages &&
                                    state.listePages.length > 0 &&
                                    state.listePages
                                    .filter((page) => {
                                        if(state.pageRecherche)
                                        {
                                            if(page.nom_page.toLocaleLowerCase().includes(state.pageRecherche.toLocaleLowerCase())) return true;
                                            if(page.url_canonique.includes(state.pageRecherche.toLocaleLowerCase())) return true;
                                            if(page.description && page.description.toLocaleLowerCase().includes(state.pageRecherche.toLocaleLowerCase())) return true;
                                            return false;
                                        }
                                        return true;
                                    })
                                    .slice(state.indexPageMin, state.indexPageMin + 5)
                                    .map((page) => {
                                        return (
                                            <tr key={creerCleUnique()}>
                                                <td>{page.url_canonique}</td>
                                                <td>{page.nom_page}</td>
                                                <td>{page.description ?? "N/D"}</td>
                                                <td>
                                                    <Button
                                                        onClick={() => {
                                                            navigate("/modifier-page?id=" + page.id)
                                                        }}
                                                    >Modifier la page</Button>
                                                    <Button
                                                        onClick={() => {
                                                            supprimer_page(page.id, state, setState)
                                                        }}
                                                    >Supprimer page</Button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </>
                }
                {
                    profil && 
                    profil.role_id !== 1 &&
                    <Row className={styles.form_affichage_page}>
                        <Col lg="4"></Col>
                        <Col lg="4">
                            <Alert variant="danger">Vous n'avez pas les droits nécessaire afin d'accéder à cette page protégé.</Alert>
                        </Col>
                        <Col lg="4"></Col>
                    </Row>
                }
            </Col>
            <Col lg="2"></Col>
        </Row>
    );
}