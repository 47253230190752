import { Alert, Button, Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import styles from "./form-ajouter-page.module.css";
import { useEffect, useState } from "react";
import { ajouter_page } from "../../services/pageService";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useNavigate } from "react-router";
import { obtenirValeur } from "../../utils/localStorageUtil";
import { obtenir_tous_les_produits } from "../../services/produitService";
import { obtenir_tous_les_types_page } from "../../services/typePageService";
import { obtenir_tous_les_roles } from "../../services/roleService";

export default function FormAjoutPage() {

    const [state, setState] = useState({
        infosPage: {
            nom_page: "",
            titre_onglet: "",
            url_canonique: "",
            description: "",
            contenu_page: null,
            produit_id: null,
            type_page_id: "1",
            role_id: "1"
        },
        url_image: "",
        alertePage: null,
        produits: null,
        typesPage: null,
        roles: null
    })
    const navigate = useNavigate();
    const profil = obtenirValeur("profil", true);

    useEffect(() => {
        !state.produits && obtenir_tous_les_produits(state, setState)
    }, [state])

    useEffect(() => {
        state.produits && 
        !state.typesPage &&
        obtenir_tous_les_types_page(state, setState)
    }, [state])

    useEffect(() => {
        state.produits && 
        state.typesPage &&
        !state.roles &&
        obtenir_tous_les_roles(state, setState)
    }, [state])

    return (
        <Row>
            <Col lg="4"></Col>
            <Col lg="4">
                {
                    profil && 
                    profil.role_id !== 1 &&
                    <Row className={styles.conteneur_ajout_page + " text-center"}>
                        <Col lg="2"></Col>
                        <Col lg="8">
                            <Alert variant="danger">Vous n'avez pas les droits nécessaire afin d'accéder à cette page protégé.</Alert>
                        </Col>
                        <Col lg="2"></Col>
                    </Row>
                }
                {
                    profil && 
                    profil.role_id === 1 &&
                    <>
                        <Row className={styles.conteneur_ajout_page + " text-center"}>
                            <Col lg="2"></Col>
                            <Col lg="8">
                                <Button
                                    onClick={() => {
                                        navigate("/voir-les-pages")
                                    }}
                                >Retourner à la liste des pages</Button>
                            </Col>
                            <Col lg="2"></Col>
                        </Row>
                        {
                            state.alertePage &&
                            <Alert variant={state.alertePage.type}>{state.alertePage.texte}</Alert>
                        }
                        <Form
                            className={styles.conteneur_ajout_page + " text-center"}
                            onSubmit={(event) => {
                                event.preventDefault();
                                ajouter_page(state, setState)
                            }}
                        >
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>Nom de la page *</FormLabel>
                                <input 
                                    className="form-control"
                                    type="text"
                                    value={state.infosPage.nom_page}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            infosPage: {
                                                ...state.infosPage,
                                                nom_page: event.target.value
                                            },
                                            alertePage: null
                                        })
                                    }}
                                    placeholder="Accueil"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>Titre de l'onglet de la page *</FormLabel>
                                <input 
                                    className="form-control"
                                    type="text"
                                    value={state.infosPage.titre_onglet}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            infosPage: {
                                                ...state.infosPage,
                                                titre_onglet: event.target.value
                                            },
                                            alerteConnexion: null
                                        })
                                    }}
                                    placeholder="GreenWood Multimedia | Xyz"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>URL canonique *</FormLabel>
                                <input 
                                    className="form-control"
                                    type="text"
                                    value={state.infosPage.url_canonique}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            infosPage: {
                                                ...state.infosPage,
                                                url_canonique: event.target.value
                                            },
                                            alerteConnexion: null
                                        })
                                    }}
                                    placeholder="/nom_page"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>Description *</FormLabel>
                                <textarea
                                    className="form-control"
                                    value={state.infosPage.description}
                                    placeholder="Entrer une description de la page..."
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            infosPage: {
                                                ...state.infosPage,
                                                description: event.target.value
                                            },
                                            alerteConnexion: null
                                        })
                                    }}
                                ></textarea>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>Image associé</FormLabel>
                                <input 
                                    className="form-control"
                                    type="file"
                                    value={state.url_image}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            url_image: event.target.value,
                                            alerteConnexion: null
                                        })
                                    }}
                                    placeholder="/nom_page"
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>Contenu de la page *</FormLabel>
                                <ReactQuill 
                                    theme="snow"
                                    className={styles.form_contenu_page}
                                    value={state.infosPage.contenu_page} 
                                    onChange={(content, delta, source, editor) => {
                                        setState({
                                            ...state,
                                            infosPage: {
                                                ...state.infosPage,
                                                contenu_page: editor.getHTML()
                                            }
                                        })
                                    }}
                                />
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>Produit associé</FormLabel>
                                <Form.Select
                                    value={state.infosPage.produit_id}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            infosPage: {
                                                ...state.infosPage,
                                                produit_id: event.target.value
                                            }
                                        })
                                    }}
                                >
                                    <option value="null">Aucun produit associé</option>
                                    {
                                        state.produits &&
                                        state.produits.length > 0 &&
                                        state.produits.map((produit) => {
                                            return (
                                                <option value={produit.id}>{produit.nom}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>Type de page</FormLabel>
                                <Form.Select
                                    value={state.infosPage.type_page_id}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            infosPage: {
                                                ...state.infosPage,
                                                type_page_id: event.target.value
                                            }
                                        })
                                    }}
                                >
                                    {
                                        state.typesPage &&
                                        state.typesPage.length > 0 &&
                                        state.typesPage.map((produit) => {
                                            return (
                                                <option value={produit.id}>{produit.nom}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </FormGroup>
                            <FormGroup>
                                <FormLabel className={styles.label_ajout_page}>Rôle associé à la page</FormLabel>
                                <Form.Select
                                    value={state.infosPage.role_id}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            infosPage: {
                                                ...state.infosPage,
                                                role_id: event.target.value
                                            }
                                        })
                                    }}
                                >
                                    {
                                        state.roles &&
                                        state.roles.length > 0 &&
                                        state.roles.map((role) => {
                                            return (
                                                <option value={role.id}>{role.nom}</option>
                                            )
                                        })
                                    }
                                </Form.Select>
                            </FormGroup>
                            <Button className={styles.bouton_ajout_page} type="submit">Ajouter la page</Button>
                        </Form>
                    </>
                }
            </Col>
            <Col lg="4"></Col>
        </Row>
    );
}