import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './index.css';
import PageAccueil from './pages/page-accueil';
import PageNonTrouve from './pages/page-non-trouve';
import PageRecherche from './pages/page-recherche';
import PageContact from './pages/page-contact';
import PageBlogue from './pages/page-blogue';
import PageAPropos from './pages/page-a-propos';
import PagePortfolio from './pages/page-portfolio';
import PageConnexion from './pages/page-connexion';
import PageService from './pages/page-service';
import PageInscription from './pages/page-inscription';
import PageArticle from './pages/page-article';
import PageProjet from './pages/page-projet';
import PageTableauDeBord from './pages/page-tableau-de-bord';
import PageMotDePasseOublier from './pages/page-mot-de-passe-oublier';
import PageEffectuerCommande from './pages/page-effectuer-commande';
import PageAjouterPage from './pages/page-ajouter-page';
import PageVoirPages from './pages/page-voir-pages';
import PageModifierPage from './pages/page-modifier-page';
import PageConfirmationCommande from './pages/page-confirmation-commande';
import PageVoirFacture from './pages/page-voir-facture';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<PageAccueil />} />
      <Route path="/contact" element={<PageContact />} />
      <Route path="/blogue" element={<PageBlogue />} />
      <Route path="/a-propos" element={<PageAPropos />} />
      <Route path="/portfolio" element={<PagePortfolio />} />
      <Route path="/se-connecter" element={<PageConnexion />} />
      <Route path="/services/:nom_service" element={<PageService />} />
      <Route path="/articles/:nom_article" element={<PageArticle />} />
      <Route path="/projets/:nom_article" element={<PageProjet />} />
      <Route path="/inscription" element={<PageInscription />} />
      <Route path="/recherche" element={<PageRecherche />} />
      <Route path="/tableau-de-bord" element={<PageTableauDeBord />} />
      <Route path="/politique-de-confidentialite" element={<PageArticle />} />
      <Route path="/mot-de-passe-oublier" element={<PageMotDePasseOublier />} />
      <Route path="/effectuer-commande" element={<PageEffectuerCommande />} />
      <Route path="/confirmation-commande" element={<PageConfirmationCommande />} />
      <Route path="/voir-les-pages" element={<PageVoirPages />} />
      <Route path="/ajouter-page" element={<PageAjouterPage />} />
      <Route path="/modifier-page" element={<PageModifierPage />} />
      <Route path="/voir-facture" element={<PageVoirFacture />} />
      <Route path="*" element={<PageNonTrouve />} />
    </Routes>
  </BrowserRouter>
);