import {  Alert, Button, Col, Row } from "react-bootstrap";
import styles from "./fiche-produit.module.css";
import { useEffect, useState } from "react";
import { obtenir_produit_par_id } from "../../services/produitService";
import OptionDuree from "./option-duree";
import OptionQuantite from "./option-quantite";
import { obtenirValeur, sauvegarderValeur } from "../../utils/localStorageUtil";
import OptionNomDomaine from "./option-nom-domaine";
import { creerCleUnique } from "../../utils/utilList";
import ReactGA from "react-ga4";

export default function FicheProduit(props) {

    const [state, setState] = useState({
        produit: null,
        produitPanier: {
            quantite: 1,
            duree: 1,
            nomDomaine: null
        },
        elementViewItemAnalytics: false,
        elementAjoutePanier: false,
        elementErreurNomDomaineVide: false,
    })

    function changerQuantite(nouvelQuantite)
    {
        setState({
            ...state,
            produitPanier : {
                ...state.produitPanier,
                quantite: nouvelQuantite
            }
        })
    }

    function changerDuree(nouvelDuree)
    {
        setState({
            ...state,
            produitPanier : {
                ...state.produitPanier,
                duree: nouvelDuree
            }
        })
    }

    function changerNomDomaine(nomDomaine)
    {
        setState({
            ...state,
            produitPanier : {
                ...state.produitPanier,
                nomDomaine: nomDomaine
            }
        })
    }

    useEffect(() => {
        !state.produit && props.contenu_page && obtenir_produit_par_id(props.contenu_page.produit_id, state, setState)
    }, [state, props])

    useEffect(() => {
        if(state.produit && !state.elementViewItemAnalytics)
        {
            ReactGA.event("view_item", {
                "currency": "CAD",
                "value": state.produit.prix_unitaire,
                "items": [
                    {
                        "item_id": state.produit.id,
                        "item_name": state.produit.nom,
                        "price": state.produit.prix_unitaire
                    }
                ]
            })
            setState({
                ...state,
                elementViewItemAnalytics: true
            })
        }
    }, [state])

    return (
        <Row className={styles.conteneur_service + " text-center"}>
            {
                state.produit &&
                state.produit.length === 0 &&
                <Row>
                    <Col lg="4"></Col>
                    <Col lg="4">
                        <Alert>Le produit que vous rechercher n'existe pas.</Alert>
                    </Col>
                    <Col lg="4"></Col>
                </Row>
            }
            {
                state.produit &&
                state.produit.id &&
                <>
                    <Col md="2"></Col>
                    <Col md="4">
                        <img 
                            loading="lazy" 
                            className="img-fluid" 
                            src={process.env.REACT_APP_NOM_DOMAINE_API + state.produit.image_produit}
                            alt={state.produit.description}
                        />
                        <p className={styles.texte_service}>{state.produit.description}</p>
                    </Col>
                    <Col md="4">
                        {
                            state.elementAjoutePanier &&
                            <Alert 
                                className={styles.alerte_ajout_panier}
                                variant="success" 
                                dismissible
                                onClose={() => {
                                    setState({
                                        ...state,
                                        elementAjoutePanier: false
                                    })
                                }}
                            >Vous avez ajouté cet élément dans le panier.</Alert>
                        }
                        {
                            state.elementErreurNomDomaineVide &&
                            <Alert 
                                className={styles.alerte_ajout_panier}
                                variant="danger" 
                                dismissible
                                onClose={() => {
                                    setState({
                                        ...state,
                                        elementErreurNomDomaineVide: false
                                    })
                                }}
                            >Veuillez remplir le champ nom de domaine.</Alert>
                        }
                        <Row className={styles.section_achat_service}>
                            <Col md="2"></Col>
                            <Col md="4">
                                <h2 className={styles.texte_montant_service}>{state.produit.prix_unitaire} $</h2>
                            </Col>
                            <Col md="4">
                                <Row>
                                    <b className={styles.texte_duree_montant}>par année</b>
                                </Row>
                                <Row>
                                    {
                                        (state.produit.id === 5 || state.produit.id === 6) &&
                                        <b className={styles.texte_duree_montant}>par utilisateur</b>
                                    }
                                </Row>
                            </Col>
                            <Col md="2"></Col>
                        </Row>
                        {
                            state.produit.options &&
                            state.produit.options.length > 0 &&
                            state.produit.options.map((option) => {
                                switch(option.nom)
                                {
                                    case "option-duree":
                                        return <OptionDuree key={creerCleUnique()} quantite={state.produitPanier.duree} changerDuree={changerDuree} />
                                    case "option-quantite":
                                        return <OptionQuantite key={creerCleUnique()} quantite={state.produitPanier.quantite} changerQuantite={changerQuantite} />
                                    case "option-nom-domaine":
                                        return <OptionNomDomaine key={creerCleUnique()} quantite={state.produitPanier.nomDomaine} changerNomDomaine={changerNomDomaine} />
                                    default:
                                        return null;
                                }
                            })
                        }
                        <Button
                            onClick={() => {
                                ReactGA.event("add_to_cart", {
                                    "currency": "CAD",
                                    "value": state.produit.prix_unitaire,
                                    "items": [
                                        {
                                            "item_id": state.produit.id,
                                            "item_name": state.produit.nom,
                                            "price": state.produit.prix_unitaire
                                        }
                                    ]
                                })
                                setState({
                                    ...state,
                                    elementViewItemAnalytics: true
                                })
                                if(state.produit.id === 3 && !state.produitPanier.nomDomaine)
                                {
                                    setState({
                                        ...state,
                                        elementErreurNomDomaineVide: true
                                    })
                                    return;
                                }
                                let panier = obtenirValeur("panier", true);
                                if(panier && panier.length > 0)
                                {
                                    panier = [
                                        ...panier,
                                        {
                                            _id: creerCleUnique(),
                                            produit_id: state.produit.id,
                                            prix_unitaire: state.produit.prix_unitaire,
                                            nombre_utilisateur: (state.produit.id === 5 || state.produit.id === 6) ? Number(state.produitPanier.quantite) : null,
                                            nom: state.produit.nom,
                                            quantite: state.produitPanier.quantite,
                                            duree: state.produitPanier.duree,
                                            nom_domaine: state.produitPanier.nomDomaine
                                        }
                                    ]
                                }
                                else
                                {
                                    panier = [
                                        {
                                            _id: creerCleUnique(),
                                            produit_id: state.produit.id,
                                            prix_unitaire: state.produit.prix_unitaire,
                                            nombre_utilisateur: (state.produit.id === 5 || state.produit.id === 6) ? Number(state.produitPanier.quantite) : null,
                                            nom: state.produit.nom,
                                            quantite: state.produitPanier.quantite,
                                            duree: state.produitPanier.duree,
                                            nom_domaine: state.produitPanier.nomDomaine
                                        }
                                    ]
                                }
                                sauvegarderValeur("panier", panier, true)
                                setState({
                                    ...state,
                                    produitPanier: {
                                        quantite: 1,
                                        duree: 1,
                                        nomDomaine: null
                                    },
                                    elementAjoutePanier: true,
                                    elementErreurNomDomaineVide: false
                                })
                            }}
                            variant="primary"
                        >Ajouter au panier</Button>
                    </Col>
                    <Col md="2"></Col>
                </>
            }
        </Row>
    );
}