import React, { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import styles from './navigation.module.css';
import { Link, useNavigate } from "react-router-dom";
import Panier from './panier';
import { BiAddToQueue, BiCart, BiChart, BiLogInCircle, BiSearch } from "react-icons/bi";
import Recherche from './recherche';
import { obtenir_menu } from '../../services/menuService';
import { obtenirValeur, supprimerValeur } from '../../utils/localStorageUtil';
import { creerCleUnique } from '../../utils/utilList';

export default function Navigation() {

    const [state, setState] = useState({
        panierAffiche: false,
        rechercheAffiche: false,
        menu: null,
        token: obtenirValeur("token", false),
        profil: obtenirValeur("profil", true)
    })
    const navigate = useNavigate();

    useEffect(() => {
        !state.menu && obtenir_menu(process.env.REACT_APP_NOM_MENU_PRINCIPAL, state, setState)
    }, [state])

    return (
        <Navbar className={styles.gw_conteneur_navigation} collapseOnSelect expand="lg">
            {
                state.panierAffiche &&
                <Panier 
                    panierAffiche={state.panierAffiche} 
                    cacherPanier={
                        () => {
                            setState({
                                ...state,
                                panierAffiche: !state.panierAffiche
                            })
                        }
                    }
                />
            }
            {
                state.rechercheAffiche &&
                <Recherche 
                    rechercheAffiche={state.rechercheAffiche} 
                    cacherRecherche={
                        () => {
                            setState({
                                ...state,
                                rechercheAffiche: !state.rechercheAffiche
                            })
                        }
                    }
                />
            }
            <Container fluid>
                <Navbar.Brand href="/">
                    <img 
                        className={styles.gw_logo}
                        src="https://api.greenwoodmultimedia.com/images/logo/greenwoodmultimedia-logo-noir-2021-11-20.png" 
                        alt="Logo de l'entreprise GreenWood Multimedia"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {
                            state.menu &&
                            state.menu.length > 0 &&
                            state.menu.map((lien) => {

                                if(lien.enfants_lien_menu.length > 0)
                                {
                                    return (
                                        <NavDropdown key={creerCleUnique()} className={styles.gw_nav_dropdown} title="Nos services" id="basic-nav-dropdown">
                                            {
                                                lien.enfants_lien_menu[0].map((lien_enfant_menu) => {
                                                    return (
                                                        <Link key={creerCleUnique()} reloadDocument className={'nav-link ' + styles.gw_nav_link} to={lien_enfant_menu.url}>{lien_enfant_menu.nom}</Link>
                                                    )
                                                })
                                            }
                                        </NavDropdown>
                                    )
                                }

                                return (
                                    <Link key={creerCleUnique()} className={'nav-link ' + styles.gw_nav_link} to={lien.url}>{lien.nom}</Link>
                                )
                            })
                        }
                    </Nav>
                    <Nav className="justify-content-end">
                        {
                            state.token &&
                            state.profil &&
                            state.profil.role_id === 1 &&
                            <NavDropdown className={styles.gw_nav_dropdown_admin} title="Gestion boutique" id="basic-nav-dropdown">
                                <Link className={'nav-link ' + styles.gw_nav_link} to="/ajouter-transaction">Ajouter une transaction</Link>
                                <Link className={'nav-link ' + styles.gw_nav_link} to="/voir-les-transactions">Voir les transactions</Link>
                                <hr />
                                <Link className={'nav-link ' + styles.gw_nav_link} to="/ajouter-produit">Ajouter un produit</Link>
                                <Link className={'nav-link ' + styles.gw_nav_link} to="/voir-les-produit">Voir les produits</Link>
                            </NavDropdown>
                        }
                        {
                            state.token &&
                            state.profil &&
                            state.profil.role_id === 1 &&
                            <NavDropdown className={styles.gw_nav_dropdown_admin} title="Gestion client" id="basic-nav-dropdown">
                                <Link className={'nav-link ' + styles.gw_nav_link} to="/ajouter-client">Ajouter un client</Link>
                                <Link className={'nav-link ' + styles.gw_nav_link} to="/voir-les-clients">Voir les clients</Link>
                            </NavDropdown>
                        }
                        {
                            state.token &&
                            state.profil &&
                            state.profil.role_id === 1 &&
                            <NavDropdown className={styles.gw_nav_dropdown_admin} title="Gestion Page" id="basic-nav-dropdown">
                                <Link className={'nav-link ' + styles.gw_nav_link} to="/ajouter-page">Ajouter page</Link>
                                <Link className={'nav-link ' + styles.gw_nav_link} to="/voir-les-pages">Voir les pages</Link>
                            </NavDropdown>
                        }
                        {
                            state.token &&
                            <Link 
                                className={'nav-link ' + styles.gw_nav_link}
                                to="/tableau-de-bord"
                            >
                                <BiChart className={styles.gw_icone}/>
                                Tableau de bord
                            </Link>
                        }
                        {
                            !state.token &&
                            <Link 
                                className={'nav-link ' + styles.gw_nav_link} 
                                to="/inscription"
                            >
                                <BiAddToQueue className={styles.gw_icone}/>
                                Inscription
                            </Link>
                        }
                        {
                            !state.token &&
                            <Link 
                                className={'nav-link ' + styles.gw_nav_link} 
                                to="/se-connecter"
                            >
                                <BiLogInCircle className={styles.gw_icone}/>
                                Se connecter
                            </Link>
                        }
                        {
                            state.token &&
                            <Link 
                                className={'nav-link ' + styles.gw_nav_link}
                                onClick={() => {
                                    supprimerValeur("token")
                                    supprimerValeur("profil")
                                    setState({
                                        ...state,
                                        token: ""
                                    })
                                    navigate("/se-connecter")
                                }}
                            >
                                <BiLogInCircle className={styles.gw_icone}/>
                                Se déconnecter
                            </Link>
                        }
                        <Link 
                            className={'nav-link ' + styles.gw_nav_link}
                            onClick={() => {
                                setState({
                                    ...state,
                                    panierAffiche: !state.panierAffiche
                                })
                            }}
                        >
                            <BiCart className={styles.gw_icone}/>
                            Panier
                        </Link>
                        <Link 
                            className={'nav-link ' + styles.gw_nav_link}
                            onClick={(event) => {
                                event.preventDefault();
                                setState({
                                    ...state,
                                    rechercheAffiche: !state.rechercheAffiche
                                })
                            }}
                        >
                            <BiSearch className={styles.gw_icone}/>
                            Recherche
                        </Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
  }