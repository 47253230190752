import { Alert, Button, Card, Modal } from "react-bootstrap";
import { BiCart } from "react-icons/bi";
import styles from "./panier.module.css";
import { obtenirValeur, sauvegarderValeur, supprimerValeur } from "../../utils/localStorageUtil";
import { useEffect, useState } from "react";
import { creerCleUnique } from "../../utils/utilList";
import { useNavigate } from "react-router";

export default function Panier(props) {

    const [state, setState] = useState({
        panier: null
    })
    const navigate = useNavigate();
    const token = obtenirValeur("token")

    useEffect(() => {
        if(!state.panier)
        {
            setState({
                ...state,
                panier: obtenirValeur("panier", true)
            })
        }
    }, [state])

    return (
        <Modal show={props.panierAffiche} onHide={props.cacherPanier}>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    <BiCart className={styles.gw_icone_panier}/>
                    Panier
                </h5>
            </Modal.Header>
            <Modal.Body>
                {
                    (!state.panier ||
                    state.panier.length === 0) &&
                    <Alert variant="warning">Aucun produit dans le panier.</Alert>
                }
                {
                    state.panier &&
                    state.panier.length > 0 &&
                    state.panier
                    .map((item_panier) => {
                        return(
                            <Card className={styles.item_panier} key={creerCleUnique()}>
                                <Card.Body>
                                    <Card.Title>{item_panier.nom}</Card.Title>
                                    <Card.Text>Prix: {item_panier.prix_unitaire}$</Card.Text>
                                    {
                                        (item_panier.produit_id === 5 || item_panier.produit_id === 6) &&
                                        <Card.Text>Nombre d'utilisateur: {item_panier.quantite}</Card.Text>
                                    }
                                    <Card.Text>Durée: {item_panier.duree} an(s)</Card.Text>
                                    {
                                        item_panier.nom_domaine &&
                                        <Card.Text>Nom de domaine: {item_panier.nom_domaine}</Card.Text>
                                    }
                                    <Button
                                        key={creerCleUnique()}
                                        variant="primary"
                                        onClick={(e) => {
                                            var nouveauPanier = state.panier.filter((item_panier_filtre) => {
                                                return item_panier_filtre._id !== item_panier._id;
                                            })
                                            sauvegarderValeur("panier", nouveauPanier, true)
                                            setState({
                                                ...state,
                                                panier: null
                                            })
                                        }}
                                    >Enlever du panier</Button>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    disabled={!state.panier || state.panier.length === 0} 
                    variant="primary"
                    onClick={() => {
                        supprimerValeur("panier")
                        setState({
                            panier: null
                        })
                    }}
                >Vider mon panier</Button>
                <Button 
                    onClick={() => {
                        if(token)
                        {
                            navigate("/effectuer-commande")
                            props.cacherPanier()
                            return;
                        }
                        navigate("/inscription?source=panier")
                        props.cacherPanier()
                    }}
                    disabled={!state.panier || state.panier.length === 0} 
                    variant="primary"
                >Passer au paiement</Button>
            </Modal.Footer>
        </Modal>
    );
}