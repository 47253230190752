import { Alert, Col, Form, FormGroup, Row } from "react-bootstrap";
import styles from "./formulaire-contact.module.css";
import { BiMailSend, BiPhone } from "react-icons/bi";
import { useState } from "react";
import { envoyer_message_courriel } from "../../services/contactService";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

export default function FormulaireContact() {

    const [state, setState] = useState({
        analyzedUserRecaptcha: false,
        recaptcha_token: "",
        informationContact: {
            prenom: "",
            nom: "",
            courriel: "",
            telephone: "",
            nomEntreprise: "",
            sujet: "",
            message: ""
        },
        alerteContact: {
            type: "",
            texte: ""
        }
    })

    return (
        <Row className={styles.form_contact}>
            <Col lg="4"></Col>
            <Col lg="4">
                <Row className="text-center">
                    <h3>Appelez-nous ou écrivez-nous un message</h3>
                    <p>Nous répondons aux courriels entre 8h AM à 5h PM durant les jours de semaine. Nous vous répondrons le plus tôt possible.</p>
                </Row>
                <Row className={"text-center " + styles.zone_lien_contact}>
                    <Col lg="3"></Col>
                    <Col lg="1">
                        <BiPhone size={24}/>
                    </Col>
                    <Col lg="5">
                        <a
                            className={styles.lien_contact}
                            href="tel:8198067613"
                        >819-806-7613</a>
                    </Col>
                    <Col lg="3"></Col>
                </Row>
                <Row className={"text-center " + styles.zone_lien_contact}>
                    <Col lg="3"></Col>
                    <Col lg="1">
                        <BiMailSend size={24}/>
                    </Col>
                    <Col lg="5">
                        <a
                            className={styles.lien_contact}
                            href="mailto:info@greenwoodmultimedia.com"
                        >info@greenwoodmultimedia.com</a>
                    </Col>
                    <Col lg="3"></Col>
                </Row>
                <GoogleReCaptcha onVerify={ (recaptcha_token) => {
                    if(!state.analyzedUserRecaptcha)
                    {
                        setState({
                            ...state,
                            analyzedUserRecaptcha: true,
                            recaptcha_token: recaptcha_token
                        })
                    }
                }} />
                <Form
                    onSubmit={(event) => {
                        event.preventDefault();
                        envoyer_message_courriel(state, setState)
                    }}
                >
                    <div 
                        id="alerte_contact"
                    >
                        {
                            state.alerteContact.texte &&
                            <Alert variant={state.alerteContact.type}>{state.alerteContact.texte}</Alert>
                        }
                    </div>
                    <FormGroup>
                        <Row>
                            <Col lg="6">
                                <input 
                                    className="form-control"
                                    placeholder="Votre prénom*"
                                    value={state.informationContact.prenom}
                                    type="text"
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            informationContact: {
                                                ...state.informationContact,
                                                prenom: event.target.value
                                            },
                                            alerteContact: {
                                                type: "",
                                                texte: ""
                                            }
                                        })
                                    }}
                                />
                            </Col>
                            <Col lg="6">
                                <input 
                                    className="form-control"
                                    placeholder="Votre nom*"
                                    type="text"
                                    value={state.informationContact.nom}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            informationContact: {
                                                ...state.informationContact,
                                                nom: event.target.value
                                            },
                                            alerteContact: {
                                                type: "",
                                                texte: ""
                                            }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col lg="6">
                                <input 
                                    className="form-control"
                                    placeholder="Votre courriel*"
                                    type="text"
                                    value={state.informationContact.courriel}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            informationContact: {
                                                ...state.informationContact,
                                                courriel: event.target.value
                                            },
                                            alerteContact: {
                                                type: "",
                                                texte: ""
                                            }
                                        })
                                    }}
                                />
                            </Col>
                            <Col lg="6">
                                <input 
                                    className="form-control"
                                    placeholder="Votre téléphone*"
                                    type="tel"
                                    value={state.informationContact.telephone}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            informationContact: {
                                                ...state.informationContact,
                                                telephone: event.target.value
                                            },
                                            alerteContact: {
                                                type: "",
                                                texte: ""
                                            }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <Row>
                            <Col lg="6">
                                <input 
                                    className="form-control"
                                    placeholder="Nom de votre entreprise*"
                                    type="text"
                                    value={state.informationContact.nomEntreprise}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            informationContact: {
                                                ...state.informationContact,
                                                nomEntreprise: event.target.value
                                            },
                                            alerteContact: {
                                                type: "",
                                                texte: ""
                                            }
                                        })
                                    }}
                                />
                            </Col>
                            <Col lg="6">
                                <input 
                                    className="form-control"
                                    placeholder="Sujet*"
                                    type="text"
                                    value={state.informationContact.sujet}
                                    onChange={(event) => {
                                        setState({
                                            ...state,
                                            informationContact: {
                                                ...state.informationContact,
                                                sujet: event.target.value
                                            },
                                            alerteContact: {
                                                type: "",
                                                texte: ""
                                            }
                                        })
                                    }}
                                />
                            </Col>
                        </Row>
                    </FormGroup>
                    <FormGroup>
                        <textarea
                            className="form-control"
                            placeholder="Votre message*"
                            value={state.informationContact.message}
                            onChange={(event) => {
                                setState({
                                    ...state,
                                    informationContact: {
                                        ...state.informationContact,
                                        message: event.target.value
                                    },
                                    alerteContact: {
                                        type: "",
                                        texte: ""
                                    }
                                })
                            }}
                        ></textarea>
                    </FormGroup>
                    <button className={"btn btn-primary " + styles.bouton_form_contact}>Envoyer mon message</button>
                </Form>
            </Col>
            <Col lg="4"></Col>
        </Row>
    );
}