import React from 'react';
import { Helmet } from "react-helmet";
import Navigation from '../composants/commun/navigation';
import TitrePage from '../composants/commun/titre-page';
import PiedPage from '../composants/commun/pied-page';

export default function PageNonTrouve() {
    return (
      <>
        <Helmet>
          <title>GreenWood Multimedia | Page non trouvé</title>
          </Helmet>
          <Navigation />
          <TitrePage>Page non trouvé</TitrePage>
        <PiedPage />
      </>
    );
  }