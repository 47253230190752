import { Form, FormGroup, FormLabel } from "react-bootstrap";
import styles from "./option.module.css";

export default function OptionQuantite(props) {

    return (
        <Form className={styles.conteneur_option}
            onSubmit={(event) => {
                event.preventDefault();
            }}
        >
            <FormGroup>
                <FormLabel>Choissisez votre nombre d'utilisateur :</FormLabel>
                <input 
                    className="form-control"
                    type="number"
                    min="1"
                    value={props.quantite}
                    onChange={(event) => {
                        props.changerQuantite(event.target.value)
                    }}
                />
            </FormGroup>
        </Form>
    );
}