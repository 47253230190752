import axios from "axios";
import { obtenirValeur, sauvegarderValeur } from "../utils/localStorageUtil";

export function obtenir_transaction_utilisateur(state, setState)
{
    const token = obtenirValeur("token")
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/transactions/utilisateur",
        {
             "headers": {
                "Authorization": "Bearer " + token
             }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    factures: response.data.contenu.transactions
                })
                return;
            }
            setState({
                ...state,
                factures: []
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                factures: []
            })
        })
}

export function obtenir_transaction_par_id_utilisateur(state, setState)
{
    
}

export function obtenir_facture_par_uuid(transaction_uuid, state, setState)
{
    const token = obtenirValeur("token")
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/transactions/" + transaction_uuid,
        {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    facture: response.data.contenu.transaction
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export function obtenir_facture_par_paypal_uuid(paypal_uuid, state, setState)
{
    const token = obtenirValeur("token")
    axios.get(
        process.env.REACT_APP_NOM_DOMAINE_API + "/transactions/paypal/" + paypal_uuid,
        {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((response) => {
            if(response.status === 200)
            {
                setState({
                    ...state,
                    facture: response.data.contenu.transaction
                })
            }
        })
        .catch((error) => {
            console.log(error)
        })
}

export async function creer_transaction(state, setState) {
    const panier = obtenirValeur("panier", true)
    const token = obtenirValeur("token")
    return axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/transactions/ajouter",
        {
            panier: panier
        },
        {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((responseTransaction) => {
            if(responseTransaction.status === 200)
            {
                return axios.post(
                    process.env.REACT_APP_NOM_DOMAINE_API + "/paiements/ajouter",
                    {
                        transaction_uuid: responseTransaction.data.contenu.transaction_uuid
                    },
                    {
                        headers: {
                            "Authorization": "Bearer " + token
                        }
                    }
                )
                    .then((responsePaiement) => {
                        return responsePaiement.data.contenu.id
                    })
                    .catch((error) => {
                        console.log(error)
                        setState({
                            ...state,
                            erreurPaypal: true,
                            alertePaypal: {
                                type:"danger",
                                texte: "Une erreur inattendue est survenue."
                            }
                        })
                    })
            }
            setState({
                ...state,
                erreurPaypal: true,
                alertePaypal: {
                    type:"danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                erreurPaypal: true,
                alertePaypal: {
                    type:"danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
}

export async function creer_transaction_avec_uuid_facture(transaction_uuid, state, setState)
{
    const token = obtenirValeur("token")
    return axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/paiements/ajouter",
        {
            transaction_uuid: transaction_uuid
        },
        {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
    )
        .then((responsePaiement) => {
            if(responsePaiement.status === 200)
            {
                return responsePaiement.data.contenu.id
            }
            setState({
                ...state,
                erreurPaypal: true,
                alertePaypal: {
                    type:"danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
        .catch((error) => {
            console.log(error)
            setState({
                ...state,
                erreurPaypal: true,
                alertePaypal: {
                    type:"danger",
                    texte: "Une erreur inattendue est survenue."
                }
            })
        })
}

export async function capturer_transaction(data, state, setState, navigate) {
    const token = obtenirValeur("token")
    return axios.post(
        process.env.REACT_APP_NOM_DOMAINE_API + "/paiements/capturer", 
        {
            paypal_uuid: data.orderID
        },
        {
            headers: {
                "Authorization": "Bearer " + token
            }
        }
      )
      .then((response) => {
        if(response.status === 200)
        {
            sauvegarderValeur("panier", [], true)
            navigate("/confirmation-commande?transaction_id=" + data.orderID)
            return;
        }
        setState({
            ...state,
            erreurPaypal: true,
            alertePaypal: {
                type:"danger",
                texte: "Une erreur inattendue est survenue."
            }
        })
      })
      .catch((error) => {
        console.log(error)
        setState({
            ...state,
            erreurPaypal: true,
            alertePaypal: {
                type:"danger",
                texte: "Une erreur inattendue est survenue."
            }
        })
      })
}