import React from 'react';
import styles from './logo-sans-nav.module.css';
import { Row } from 'react-bootstrap';

export default function LogoSansNav() {
    return (
        <Row className='text-center'>
            <a
                href='/'
            >
                <img 
                    className={styles.gw_logo}
                    src="https://api.greenwoodmultimedia.com/images/logo/greenwoodmultimedia-logo-noir-2021-11-20.png" 
                    alt="Logo de l'entreprise GreenWood Multimedia"
                />
            </a>
        </Row>
    );
  }