import { Alert, Card, Col, Row } from "react-bootstrap";
import styles from "./pied-page.module.css";
import { BiMailSend, BiPhone } from "react-icons/bi";
import PiedCopyright from "./pied-copyright";
import { FacebookProvider, Page } from 'react-facebook';
import { Timeline } from "react-twitter-widgets";
import React, { useEffect, useState } from "react";
import { obtenir_articles } from "../../services/articleService";
import { creerCleUnique } from "../../utils/utilList";

export default function PiedPage() {

    const [state, setState] = useState({
        listeArticle: null
    })

    useEffect(() => {
        !state.listeArticle && obtenir_articles(state, setState)
    }, [state])

    return (
        <>
            <Row className={styles.pied_page}>
                <Col lg="1"></Col>
                <Col lg="10">
                    <Row className="text-right">
                        <Col lg="4">
                            <h5 className={styles.titre_coordonees_pied_page}>Nos coordonnées</h5>
                            <Row className={styles.section_coordonnes}>
                                <Col xs="1">
                                    <BiPhone className={styles.icone_pied_page}/>
                                </Col>
                                <Col xs="11">
                                    <p>819-806-7613</p>
                                </Col>
                            </Row>
                            <Row className={styles.section_coordonnes}>
                                <Col xs="1">
                                    <BiMailSend className={styles.icone_pied_page}/>
                                </Col>
                                <Col xs="11">
                                    <p>info@greenwoodmultimedia.com</p>
                                </Col>
                            </Row>
                            <h5 className={styles.titre_coordonees_pied_page}>Notre dernier article</h5>
                            {
                                state.listeArticle &&
                                state.listeArticle.length === 0 &&
                                <Alert variant="warning">Aucun article à afficher.</Alert>
                            }
                            {
                                state.listeArticle &&
                                state.listeArticle.length > 0 &&
                                state.listeArticle
                                .slice(0, 1)
                                .map((article) => {
                                    return (
                                        <a 
                                            key={creerCleUnique()}
                                            className={styles.lien_article}
                                            href={article.url_canonique}
                                        >
                                            <Card className={styles.carte_article_bas_page}>
                                                {
                                                    article.url_image &&
                                                    <Card.Img 
                                                        variant="img-top"
                                                        src={process.env.REACT_APP_NOM_DOMAINE_API + article.url_image}
                                                    />
                                                }
                                                <Card.Title className={styles.titre_article}>{article.nom_page}</Card.Title>
                                            </Card>
                                        </a>
                                    );
                                })
                            }
                        </Col>
                        <Col lg="1"></Col>
                        <Col lg="3">
                            <h5 className={styles.titre_coordonees_pied_page}>Notre Facebook</h5>
                            <FacebookProvider 
                                appId="276241978829377"
                                language="fr_CA"
                                lazy
                            >
                                <Page
                                    href="https://www.facebook.com/greenwoodmultimedia" 
                                    tabs="timeline" 
                                    height='400'
                                />
                            </FacebookProvider>   
                        </Col>
                        <Col lg="1"></Col>
                        <Col lg="3">
                            <h5 className={styles.titre_coordonees_pied_page}>Notre twitter</h5>
                            <Timeline
                                dataSource={{
                                    sourceType: 'profile',
                                    screenName: 'gw_multimedia'
                                }}
                                options={{
                                    height: '400',
                                    lang: "fr_CA"
                                }}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col lg="1"></Col>
            </Row>
            <PiedCopyright />
        </>
    );
}