import { Alert, Button, Col, Form, FormGroup, FormLabel, Row } from "react-bootstrap";
import styles from "./form-inscription.module.css";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { activer_utilisateur_inscription, inscription } from "../../services/utilisateurService";

export default function FormInscription() {

    const [state, setState] = useState({
        analyzedUserRecaptcha: false,
        recaptcha_token: "",
        etapeInscription: 1,
        infosInscription: {
            prenom: "",
            nom: "",
            courriel: "",
            mot_de_passe: "",
            nouveau_mot_de_passe: "",
            numero_civique: "",
            nom_ville: "",
            province_etat_territoire: "",
            pays: "",
            code_postal: "",
            code_reinitialisation: ""
        },
        alerteInscription: {
            type: "",
            texte: ""
        }
    })
    const navigation = useNavigate();
    const sourceInscription = useLocation().search.split("=")[1];

    return (
        <Row className={styles.conteneur_form_inscription + " text-center"}>
            <Col lg="4"></Col>
            <Col lg="4">
                <GoogleReCaptcha onVerify={ (recaptcha_token) => {
                    if(!state.analyzedUserRecaptcha)
                    {
                        setState({
                            ...state,
                            analyzedUserRecaptcha: true,
                            recaptcha_token: recaptcha_token
                        })
                    }
                }} />
                <div 
                    id="alerte_contact"
                >
                    {
                        state.alerteInscription.texte &&
                        <Alert variant={state.alerteInscription.type}>{state.alerteInscription.texte}</Alert>
                    }
                </div>
                {
                    state.etapeInscription === 1 &&
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            inscription(state, setState, navigation)
                        }}
                    >
                        {
                            (sourceInscription === "commande" ||
                            sourceInscription === "panier") &&
                            !state.alerteInscription.texte &&
                            <Alert variant="success" dismissible>Vous devez vous inscrire afin de compléter votre commande. Si vous avez déjà un compte, veuillez <a className={styles.bouton_connexion_inscription} href="/se-connecter">vous connecter</a> et retourner dans le panier par la suite pour compléter votre commande.</Alert>
                        }
                        <h5 className={styles.premier_titre_section_commande}>Informations de base</h5>
                        <FormGroup>
                            <FormLabel>Votre prénom *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosInscription.prenom}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            prenom: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="John"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Votre nom *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosInscription.nom}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            nom: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="Doe"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Votre courriel *</FormLabel>
                            <input 
                                className="form-control"
                                type="email"
                                value={state.infosInscription.courriel}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            courriel: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="johndoe@domaine.com"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Votre mot de passe *</FormLabel>
                            <input 
                                className="form-control"
                                type="password"
                                value={state.infosInscription.mot_de_passe}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            mot_de_passe: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="monMotDePasse"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Entrer de nouveau votre mot de passe *</FormLabel>
                            <input 
                                className="form-control"
                                type="password"
                                value={state.infosInscription.nouveau_mot_de_passe}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            nouveau_mot_de_passe: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="monMotDePasse"
                            />
                        </FormGroup>
                        <h5 className={styles.titre_section_commande}>Informations de facturation</h5>
                        <FormGroup>
                            <FormLabel>Numéro civique et rue *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosInscription.numero_civique}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            numero_civique: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="1234, Rue Berry"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Nom de la ville *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosInscription.nom_ville}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            nom_ville: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="Montréal"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Nom de la province/État/Territoire *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosInscription.province_etat_territoire}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            province_etat_territoire: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="Québec"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Pays *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosInscription.pays}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            pays: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="Canada"
                            />
                        </FormGroup>
                        <FormGroup>
                            <FormLabel>Code postal *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosInscription.code_postal}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            code_postal: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="H67 199"
                            />
                        </FormGroup>
                        <Button className={styles.bouton_inscription} type="submit">M'inscrire</Button>
                    </Form>
                }
                {
                    state.etapeInscription === 2 &&
                    <Form
                        onSubmit={(event) => {
                            event.preventDefault();
                            activer_utilisateur_inscription(state, setState, navigation, sourceInscription);
                        }}
                    >
                        <FormGroup>
                            <FormLabel>Votre code d'activation *</FormLabel>
                            <input 
                                className="form-control"
                                type="text"
                                value={state.infosInscription.code_reinitialisation}
                                onChange={(event) => {
                                    setState({
                                        ...state,
                                        infosInscription: {
                                            ...state.infosInscription,
                                            code_reinitialisation: event.target.value
                                        },
                                        alerteConnexion: {
                                            type: "",
                                            texte: ""
                                        }
                                    })
                                }}
                                placeholder="134567"
                            />
                        </FormGroup>
                        <Button type="submit">Activer mon compte</Button>
                    </Form>
                }
            </Col>
            <Col lg="4"></Col>
        </Row>
    );
}