import { Col, Row } from "react-bootstrap";
import styles from "./banniere-accueil.module.css";

export default function BanniereAccueil() {
    return (
        <Row className={styles.banniere + " text-center"}>
            <Col className={styles.boite_slogan} lg="12">
                <h2 className={styles.boite_slogan_sous_titre}>DES SERVICES WEBS PUISSANTS</h2>
                <h1 className={styles.boite_slogan_titre}>Pousser</h1>
                <h1 className={styles.boite_slogan_titre}>son entreprise</h1>
                <h1 className={styles.boite_slogan_titre}>vers l'avenir</h1>
                <a className={"btn btn-primary " + styles.bouton_call_to_action} href="/portfolio">
                    Découvrir notre portfolio
                </a>
            </Col>
        </Row>
    );
}