import { Col, Row } from "react-bootstrap";
import styles from "./banniere-avis.module.css";

export default function BanniereAvis() {
    return (
        <Row className={"text-center " + styles.conteneur_banniere_avis}>
            <Col lg="12">
                <Row className={styles.conteneur_avis}>
                    <h4 className={styles.titre_conteneur_avis}>Prêt à lancer votre projet ?</h4>
                    <a href="/contact" className={"btn btn-primary " + styles.bouton_call_to_action}>Discuter de mon projet</a>
                </Row>
            </Col>
        </Row>
    );
}